<script setup>
 const contactMail = 'legal@cloudactivereception.com';
 const dpdMail = 'dpd@inforserveis.es';
</script>
<template>
    <section class="privacy-wrapper">
      <article class="privacy-title">
        {{ $t('privacy.title') }}
      </article>
      <article class="privacy-block">
        <div class="privacy-text-2">
          {{ $t('privacy.text_0_1') }}
        </div>
        <div class="privacy-text-2">
          {{ $t('privacy.text_0_2') }}
        </div>
      </article>
      <article class="privacy-block">
        <div class="privacy-subtitle">
          {{ $t('privacy.text_1_title') }}
        </div>
        <div class="privacy-text-2">
          {{ $t('privacy.text_1_1') }}
        </div>
        <div class="privacy-text-2">
          {{ $t('privacy.text_1_2') }}
        </div>
      </article>
      <article class="privacy-block">
        <div class="privacy-subtitle">
          {{ $t('privacy.text_2_title') }}
        </div>
        <div class="privacy-subtitle">
          {{ $t('privacy.text_2_1_subtitle') }}
        </div>
        <div class="privacy-text-2">
          {{ $t('privacy.text_2_1_1') }}
        </div>
        <div class="privacy-text-2PL">
          {{ $t('privacy.text_2_1_2') }}
        </div>
        <div class="privacy-text-2PL">
          {{ $t('privacy.text_2_1_3') }}
        </div>
        <div class="privacy-text-2">
          {{ $t('privacy.text_2_1_4') }}
        </div>
      </article>
      <article class="privacy-block">
        <div class="privacy-subtitle">
          {{ $t('privacy.text_2_2_subtitle') }}
        </div>
        <div class="privacy-text-2">
          {{ $t('privacy.text_2_2_1') }}
        </div>
        <div class="privacy-text-2PL">
          {{ $t('privacy.text_2_2_2') }}
        </div>
        <div class="privacy-text-2PL">
          {{ $t('privacy.text_2_2_3') }}
        </div>
        <div class="privacy-text-2PL">
          {{ $t('privacy.text_2_2_4') }}
        </div>
        <div class="privacy-text-2PL">
          {{ $t('privacy.text_2_2_5') }}
        </div>
        <div class="privacy-text-2PL">
          {{ $t('privacy.text_2_2_6') }}
        </div>
      </article>
      <article class="privacy-block">
        <div class="privacy-subtitle">
          {{ $t('privacy.text_2_3_subtitle') }}
        </div>
        <div class="privacy-text-2">
          {{ $t('privacy.text_2_3_1') }}
        </div>
        <div class="privacy-text-1">
          {{ $t('privacy.text_2_3_2') }}
        </div>
      </article>
      <article class="privacy-block">
        <div class="privacy-subtitle">
          {{ $t('privacy.text_3_title') }}
        </div>
        <div class="privacy-text-2">
          {{ $t('privacy.text_3_1') }}
        </div>
      </article>
      <article class="privacy-block">
        <div class="privacy-subtitle">
          {{ $t('privacy.text_4_title') }}
        </div>
        <div class="privacy-text-2">
          {{ $t('privacy.text_4_1') }}
        </div>
      </article>
      <article class="privacy-block">
        <div class="privacy-subtitle">
          {{ $t('privacy.text_5_title') }}
        </div>
        <div class="privacy-text-2">
          {{ $t('privacy.text_5_1') }}
        </div>
      </article>
      <article class="privacy-block">
        <div class="privacy-subtitle">
          {{ $t('privacy.text_6_title') }}
        </div>
        <div class="privacy-text-2">
          {{ $t('privacy.text_6_1') }}
        </div>
      </article>
      <article class="privacy-block">
        <div class="privacy-subtitle">
          {{ $t('privacy.text_7_title') }}
        </div>
        <div class="privacy-text-2">
          {{ $t('privacy.text_7_1') }}
        </div>
      </article>
      <article class="privacy-block">
        <div class="privacy-subtitle">
          {{ $t('privacy.text_8_title') }}
        </div>
        <div class="privacy-text-2">
          {{ $t('privacy.text_8_1', {DPD_MAIL: dpdMail}) }}
        </div>
      </article>
      <article class="privacy-block">
        <div class="privacy-subtitle">
          {{ $t('privacy.text_9_title') }}
        </div>
        <div class="privacy-text-2">
          {{ $t('privacy.text_9_1', {CONTACT_MAIL: contactMail}) }}
        </div>
      </article>

      
    </section>
  </template>
  
  <style lang="scss" scoped>
  .privacy-wrapper {
    padding-top: 90px;
    padding-bottom: 230px;
    max-width: 990px;
    margin: 0 auto;
    .privacy-title {
      font-size: 60px;
      letter-spacing: -3px;
      font-family: 'CircularStd-Black';
      padding-bottom: 55px;
    }
    .privacy-block {
      font-size: 18px;
      letter-spacing: -0.9px;
      padding-bottom: 7px;
      .privacy-subtitle {
        font-size: 30px;
        letter-spacing: -1.5px;
        font-family: 'CircularStd-Bold';
        padding-bottom: 10px;
      }
      .privacy-text-1 {
        padding-bottom: 0px;
      }
      .privacy-text-2 {
        padding-bottom: 25px;
      }
      .privacy-text-3 {
        padding-bottom: 50px;
      }
      .privacy-text-2PL {
        padding-left: 2rem;
        padding-bottom: 25px;
      }
      a {
        color: #0047ba;
        text-decoration: none;
        position: relative;
      }
      a:before {
        content: '';
        position: absolute;
        width: 100%;
        height: 1px;
        border-radius: 4px;
        background-color: #0047ba;
        bottom: -2px;
        left: 0;
        transform-origin: right;
        transform: scaleX(0);
        transition: transform 0.3s ease-in-out;
      }
      a:hover:before {
        transform-origin: left;
        transform: scaleX(1);
      }
    }
  }
  </style>