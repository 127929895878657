<script setup>
import { ref } from "vue";
import { storeToRefs } from "pinia";
import { useUserPermission } from '@/stores/userPermission.js';
import LocalStorage from '@/controller/LocalStorage.js';

const userKey = ref(LocalStorage.getKey());
const userPermissionStore = useUserPermission();
const userPermission = storeToRefs(userPermissionStore);
const userPermissions = ref(null)
const { getUserPermission: userPermissionData } = storeToRefs(userPermission);
const emit = defineEmits(["permissionsLoaded"]);

if(userPermissionData && userPermissionData.value != '0') {
    if (localStorage.getItem('yautyData')) {
        userPermissionStore.fetchUserPermissions({ k: userKey.value }).then((res) => {
            userPermissions.value = res;
            return res;
        });
    }
}
emit("permissionsLoaded");


</script>
<template>
</template>