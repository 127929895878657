import { createRouter, createWebHistory } from "vue-router";
import { getActivePinia } from 'pinia';
import { getPageTitle } from "../utils/PageTitle.js";
import Login from "../components/login/Login.vue";
import Register from "../pages/Register.vue";
import Invitations from "../pages/Invitations.vue";
import Invitation from "../pages/Invitation.vue";
import Workfile from "../pages/Workfile.vue";
import ActivateAccount from "../pages/ActivateAccount.vue";
import RecoverPassword from "../pages/RecoverPassword.vue";
import RecoverAccount from "../pages/RecoverAccount.vue";
// import CreateInvitation from "../components/configuration/invitation/CreateInvitation.vue";
import Cookies from "../pages/cookies/CookiesView.vue";
import Privacy from "../pages/privacy/PrivacyView.vue";
import { isAuthorized } from "../utils/Authorization.js";
import { setBranding } from "../utils/Branding.js";
import { useWorkersStore } from '../stores/workers.js';
import { useGlobalStore } from '../stores/global.js';
import { useCommunicatorStore } from '../stores/communicator.js';
import { START_LOCATION } from 'vue-router';
const router = createRouter({
  history: createWebHistory(import.meta.env.BASE_URL),
  routes: [
    {
        path: "/",
        name: "home",
        component: () => import("@/components/home/Home.vue"), 
        meta: { 
          title: "home" 
        }
      },
      {
        path: "/login",
        name: "login",
        component: Login,
        meta: { 
          hideNavbar: true,
          hideFooter: true,
          title: "login" 
        }
      },
      {
        path: "/logout",
        name: "logout",
        component: () => import("@/components/logout/Logout.vue"),
        meta: { 
          hideNavbar: true,
          hideFooter: true,
          title: "logout"
        }
      },
      {
        path: "/register",
        name: "register",
        component: Register,
        meta: { 
          hideNavbar: true,
          hideFooter: true,
          title: "register" 
        }
      },
      {
        path: "/user",
        name: "user",
        component: () => import("@/components/user/User.vue"),
        meta: { 
          title: "user"
        }
      },
      {
        path: "/vehicles",
        name: "vehicles",
        component: () => import("@/components/vehicles/Vehicles.vue"),
        meta: { 
          title: "vehicles"
        }
      },
      {
        path: "/vehicle/:id",
        name: "vehicle",
        component: () => import("@/components/vehicle/Vehicle.vue"),
        meta: { 
          title: "vehicle"
        }
      },
      // {
      //   path: "/vehicle/add",
      //   name: "vehicle/add",
      //   component: () => import("@/components/vehicle/add/VehicleAdd.vue"),
      //   meta: { 
      //     title: "vehicle_add"
      //   }
      // },
      {
        path: "/workfiles",
        name: "workfiles",
        component: () => import("@/components/workfiles/Workfiles.vue"),
        meta: { 
          title: "workfiles"
        }
      },
      {
        path: "/workfile/:id",
        name: "workfile",
        component: Workfile,
        meta: { 
          title: "workfile"
        }
      },
      {
        path: "/:pathMatch(.*)*",
        name: "notFound",
        component: () => import("@/components/notFound/NotFound.vue"),
        meta: { 
          title: "Not found"
        }
      },
      {
        path: "/invitation/:id",
        name: "invitation",
        component: Invitation,
        meta: { 
          title: "invitation"
        }
      },
      {
        path: "/invitations/:id",
        name: "invitations",
        component: Invitations,
        meta: { 
          hideNavbar: true,
          title: "invitations"
        }
      },
      {
        path: "/invitations-list",
        name: "invitationsList",
        component: () => import("@/components/invitations/list/InvitationsList.vue"),
        meta: { 
          title: "invitations"
        }
      },
      {
        path: "/activate/:id",
        name: "activate",
        component: ActivateAccount,
        meta: { 
          hideNavbar: true,
          hideFooter: true,
          title: "activateAccount"
        }
      },
      {
        path: "/configuration",
        name: "configurationList",
        component: () => import("@/components/configuration/Configuration.vue"),
        meta: { 
          title: "configuration",
          auth: "organizationMenu"
        }
      },
      {
        path: "/configuration/:id",
        name: "configuration",
        component: () => import("@/components/configuration/components/body/components/ConfigurationUser.vue"),
        meta: { 
          title: "configuration",
          auth: ["organizationUsersManage","yautyadmin"]
        }
      },
      {
        path: "/recover-account",
        name: "recoverAccount",
        component: RecoverAccount,
        meta: { 
          hideNavbar: true,
          hideFooter: true,
          title: "recoverAccount" 
        }
      },  
      {
        path: "/passwordRecovery/:id",
        name: "recoverPassword",
        component: RecoverPassword,
        meta: { 
          hideNavbar: true,
          hideFooter: true,
          title: "recoverPassword" 
        }
      },  
      // {
      //   path: "/create-invitation",
      //   name: "createInvitation",
      //   component: CreateInvitation,
      //   meta: { 
      //     title: "createInvitation",
      //     auth: "userCreate"
      //   }
      // },  
      {
        path: "/notifications",
        name: "notifications",
        component: () => import("@/components/notifications/Notifications.vue"),
        meta: { 
          title: "notifications"
        }
      },        
      {
        path: "/create-appointment",
        name: "createAppointment",
        component: () => import("@/components/appointment/Appointment.vue"),
        meta: { 
          title: "createAppointment",
          auth: ["yautyadmin", "createAppointment"]
        }
      },
      {
        path: "/privacy",
        name: "privacy",
        component: Privacy,
        meta: { 
          hideNavbar: true,
          hideFooter: true,
          title: "privacy" 
        }
      }, 
      {
        path: "/cookies",
        name: "cookies",
        component: Cookies,
        meta: { 
          hideNavbar: true,
          hideFooter: true,
          title: "cookies" 
        }
      },
      {
        path: "/yfs",
        name: "yfs",
        component: () => import("@/components/yfs/yfs.vue"),
        meta: { 
          title: "yfs",
          auth: "yautyadmin"
        }
      },  
      {
        path: "/yfs/:id",
        name: "userConfiguration",
        component: () => import("@/components/yfs/User.vue"),
        meta: { 
          title: "yfs",
          auth: "yautyadmin"
        }
      },      
    ]
});

router.beforeEach(async (to, from, next) => {
  window.document.title = getPageTitle(to.meta.title);
  const workersStore = useWorkersStore();
  const communicatorStore = useCommunicatorStore();
  const globalsStore = useGlobalStore();
  if(from.name == undefined){
    // this is a web refresh (F5)
    globalsStore.setCurrentView(to.name);
  }
  if((to.name == 'login' || to.name == 'logout')) {
    workersStore.stopServiceWorkers();
  }
  if(to.name == 'logout') {
    workersStore.stopServiceWorkers();
    localStorage.removeItem('yautyData');
    getActivePinia()._s.forEach(function(store){
      if(store.$id == "appointment") {
        store.userKey = '';
      }
      store.$reset()
    });
    next("/login")
  } else if(!localStorage.getItem('yautyData') && to.name!='login' && to.name!='register' && to.name!='invitations' && to.name!='activate' && to.name!='recoverPassword' && to.name!='recoverAccount' && to.name!='cookies' && to.name!='privacy') {
    localStorage.removeItem('yautyData');
    getActivePinia()._s.forEach(store => store.$reset());
    next("/login")
  } else if(from.name == 'createAppointment'){
    getActivePinia()._s.forEach(function(store){
      if(store.$id == "appointment") {
        store.userKey = '';
        store.$reset();
      }
      if(store.$id == "organization") {
        store.dealersList = [];
      };
        
    });
    next();
  }else if(to.meta.auth){
    setBranding();
    communicatorStore.resetSessionExpiration();
    if (!workersStore.areWorkersRunning) {
      workersStore.startServiceWorkers();
    }
    if(typeof to.meta.auth == 'string'){
      if(isAuthorized(to.meta.auth)) {
        next();
      }else{
        next("/");
      }
    }else{
      to.meta.auth.forEach(element => {
        if(isAuthorized(element)) {
          next();
        }
      });
      next("/");
    }
  } else {
    communicatorStore.resetSessionExpiration();
    if (!workersStore.areWorkersRunning) {
      workersStore.startServiceWorkers();
    }
    setBranding();
    next();
  }
});

export default router;
