<script setup>
import { useI18n } from "vue-i18n";
import { onMounted, ref } from "vue";
import LocalStorage from '@/controller/LocalStorage.js';
import LoginYauty from "@/components/login/LoginYauty.vue";
import LocaleSwitcher from "@/components/common/locale/LocaleSwitcher.vue";
import CookiesModal from '@/components/legal/CookiesModal.vue';
import Cookies from 'js-cookie';
const branding = ref(LocalStorage.getBranding());
const { t } = useI18n();
const i18n = useI18n();
const imgYautyPath = branding.value?.login_logo && branding.value.login_logo != '' ? branding.value.login_logo : "../public/assets/login/login_logo.svg";
const imgLogginPath = branding.value?.login_image ?? '../public/assets/login/login_w.jpg';
const showCookiesModal = ref(false);
const locale = ref(i18n.locale);

function onChangeInput() {
  localStorage.setItem('yautyLanguage', locale.value);
}
function closeModal() {
    showCookiesModal.value = false;
}
if (typeof Cookies.get('yautyRequired') == 'undefined' || Cookies.get('yautyRequired') == 'false'){
  showCookiesModal.value = true;
}
onMounted(() => {
  if (localStorage.getItem('yautyLanguage')) {
    locale.value = localStorage.getItem('yautyLanguage') ?? 'es';
  }
});
</script>
<template>
  <section class="login-container">
    <div class="login-section--left">
      <img :src="imgLogginPath" />
    </div>
    <div class="login-section--right">
      <div class="login-logo">
        <img :src="imgYautyPath" />
      </div>
      <div class="login-message_1">
        {{ $t("login.welcome") }}
      </div>
      <div class="login-message_2">
        {{ $t("login.welcome_subtitle") }}
      </div>
      <div class="login-form">
        <LoginYauty/>
      </div>
    </div>
  </section>
  <div class="language-selector">
    <div class="language__selector">
      <LocaleSwitcher :lang="locale" @changeInput="onChangeInput" :mode="1"/>
    </div>
  </div>
  <CookiesModal :show="showCookiesModal" @closeModal="closeModal()"/>
</template>

<style lang="scss" scoped>
.login-container {
  display: grid;
  grid-template-columns: 50% 50%;
  justify-content: center;
  height: calc(100vh - 60px);
  .login-section--left {
	width: 50dvw;
	height: 100dvh;
	img {
		height: 100%;
		width: 100%;
		object-fit: cover;
	}
  }
  .login-section--right{
	padding-left: 8rem;
	margin-top: 10dvh;
	.login-message_1{
		text-align: left;
		font: normal normal normal 51px/29px 'CircularStd-Book';
		letter-spacing: -2.55px;
		color: #000000;
		margin-top: 60px;
	}
	.login-message_2{
		text-align: left;
		font: normal normal normal 18px/29px 'CircularStd-Book';
		letter-spacing: -0.9px;
		color: #000000;
		opacity: 1;
		margin-top: 28px;
	}
	}
  @media (min-width: 360px) {
	grid-template-columns: 0% 100%;
	.login-section--left {
		width: 0;
		img {
			width: 0;
			object-fit: cover;
		}
	}	
	.login-section--right{
		padding-left: 4rem;
	}
  }
  @media (min-width: 520px) {
	grid-template-columns: 40% 60%;
	.login-section--left {
		width: 40dvw;
		img {
			width: 40dvw;
			object-fit: cover;
		}
	}	
	.login-section--right{
		padding-left: 4rem;
	}
  }
  @media (min-width: 768px) {
	grid-template-columns: 50% 50%;
	.login-section--left {
		width: 50dvw;
		img {
			width: 50dvw;
			object-fit: cover;
		}
	}
	.login-section--right{
		padding-left: 8rem;
	}
  }
  @media (min-width: 992px) {
  }
  @media (min-width: 1200px) {
  }
  @media (min-width: 1400px) {
  }
}
.language-selector{
	position: absolute;
	bottom: -45px;
	right: 15px;
	width: 175px;
}
</style>
