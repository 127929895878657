<script setup>
import { ref, onMounted } from "vue";
import { useRouter } from "vue-router";
import { useI18n } from "vue-i18n";
import MazSelect from 'maz-ui/components/MazSelect';
const i18n = useI18n();
const { t } = useI18n();
const defaultLocale = ref(i18n.locale.value);
const router = useRouter();
const localeArray = {
  ca: 'Català',
  es: 'Español',
  gb: 'British english',
  us: 'American english',
  fr: 'Français',
  pt: 'Português',
};
const localeArraySimple = {
  ca: 'CA',
  es: 'ES',
  gb: 'GB',
  us: 'US',
  fr: 'FR',
  pt: 'PT',
};
const langOptions = [
  {value: 'ca',label: 'Català'},
  {value: 'es',label: 'Español'},
  {value: 'gb',label: 'British english'},
  {value: 'us',label: 'American english'},
  {value: 'fr',label: 'Français'},
  {value: 'pt',label: 'Português'}
]
const props = defineProps({
  lang: String,
  mode: Number,
});
const locale = ref(i18n.locale)

const emit = defineEmits(['changeInput', 'save']);

function switchLocale(loc) {
  router.replace({ params: { lang: locale.value } });
  emit('changeInput', locale.value, 'lang');
  emit('save');
}

if(locale.value == '' || !(i18n.availableLocales).includes(locale.value) || props.lang == '' || !(i18n.availableLocales).includes(props.lang)) {
  locale.value = 'es'
}
onMounted(() => {
});

</script>

<template>
  <div class="local-switcher">
    <!-- <select v-model="locale" @change="switchLocale(i18n.locale)">
      <option
        v-for="(locale, i) in Object.keys(localeArray)"
        :key="`locale-${i}`"
        :value="locale"
        :selected="locale === $i18n.locale"
        class="locale-switcher-option"
      >
        <span v-if="props.mode == 1">
          {{ localeArraySimple[locale] }}
        </span>
        <span v-else>
          {{ localeArray[locale] }}
        </span>
      </option>
    </select> -->
    <MazSelect
        v-model="locale"
        :label="t('field.language')"
        color="primary"
        listPosition="top left"
        class="language-selector"
        :options="langOptions"
        rounded-size="full"
        size="sm"
        @update:model-value="switchLocale"
    />
  </div>
</template>

<style lang="scss">
select {
  border: 1px solid #e2e8f0;
  border-radius: 0.24rem;
  color: black;
  padding: 2px 10px;
  height: 38px;
  width: 100%;
  background-color: white !important;
  option{
    // font-family: 'CircularStd-Book' !important;
    color: black;
    font-size: 17px;
  }
  .locale-switcher-option span {
    // font-family: 'CircularStd-Book' !important;
    color: black;
    font-size: 17px;
  }
}
select:focus {
  outline: none;
}
.language-selector{
  // max-width: 175px;
  width: 100%;
  .m-select-list{
    width: 100%;
    ::-webkit-scrollbar {
      display: none;
    }
    -ms-overflow-style: none;
    scrollbar-width: none;
  }
}
</style>
