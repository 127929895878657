<script setup>
import { humanized } from '@/utils/Dates.js';

const props = defineProps({
    timestamp: String,
});

function getEllapsedTime() {
    return humanized(props.timestamp);
}
</script>

<template>
    <span class="humanized-date">{{ getEllapsedTime() }}</span>
</template>