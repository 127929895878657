<script setup>
import { ref } from "vue";
import { useI18n } from "vue-i18n";
import Cookies from 'js-cookie';
import { useRouter } from "vue-router";
import MazDialog from 'maz-ui/components/MazDialog';
import YBtn from '@/components/common/form/YBtn/YBtn.vue';
import MazSwitch from 'maz-ui/components/MazSwitch';
const cookie = '../public/assets/login/icons/cookie.svg';
const router = useRouter();
const { t } = useI18n();
const props = defineProps({
  show: Boolean
});
const cookies = ref([
    {
        name: 'yautyStatistics',
        label: t('cookies.statistics'),
        description: t('cookies.statistics_text'),
        value: false,
        popover: ref(null),
        disabled: false
    },
    {
        name: 'yautyRequired',
        label: t('cookies.required'),
        description: t('cookies.required_text'),
        value: true,
        popover: ref(null),
        disabled: true
    }
]);

const showMore = ref(false);
const modalWidth = '80%';
const modalHeight = '80dvh';
const privacyLink = `<router-link :to="{ name: 'privacy' }">${t('cookies.privacy_policy')}</router-link>`;
const cookiesLink = `<router-link :to="{ name: 'cookies' }">${t('cookies.cookies_policy')}</router-link>`;
const cookies1_text = t('cookies.content_1').replace('##PRIVACY_POLICY##', privacyLink).replace('##COOKIES_POLICY##', cookiesLink);
const cookies2_text = t('cookies.content_2-1').replace('##PRIVACY_POLICY##', privacyLink).replace('##COOKIES_POLICY##', cookiesLink);
    
const emit = defineEmits("closeModal");
function closeModal() {
  emit('closeModal');
}

function showMoreInfo(show){
    showMore.value = show;
}
function acceptCookies(){
    Cookies.set('yautyRequired', true, { expires: 365, path: '/', secure: true, sameSite: 'strict' });
    closeModal()
}
function acceptCookiesSelection(){
    cookies.value.forEach(cookie => {
        Cookies.set(cookie.name, cookie.value, { expires: 365, path: '/', secure: true, sameSite: 'strict' });
    });
    closeModal()
}
function getParts(text){
    const parts = text.split('##');
    return parts.map(part => {
      if (part === 'PRIVACY_POLICY') {
        return { type: 'link', name: 'privacy', text: t('cookies.privacy_policy') };
      } else if (part === 'COOKIES_POLICY') {
        return { type: 'link', name: 'cookies', text: t('cookies.cookies_policy') };
      } else {
        return { type: 'text', text: part };
      }
    });
}
function navigate(name){
    router.push({ name });
}
</script>

<template>
    <transition name="modal">
        <MazDialog v-model="props.show" title="Dialog Title" class="cookies_modal">
            <template #title>
                <div class="d-flex align-items-center title-wrapper">
                    <img :src="cookie" style="width:50px;height:50px;"/>
                    <div class="cookies-modal-title">
                        {{ $t("cookies.header") }}
                    </div>
                </div>
            </template>
            <template #default>
                <div class="cookies-first-step" v-show="!showMore">
                    <span v-for="(part, index) in getParts($t('cookies.content_1'))" :key="index">
                        <a class="linkText" v-if="part.type === 'link'" href="#" @click.prevent="navigate(part.name)">{{ part.text }}</a>
                        <span v-else>{{ part.text }}</span>
                    </span>
                </div>
                <div class="cookies-second-step" v-show="showMore">
                    <!-- <p v-html="cookies2_text"></p> -->
                    <span v-for="(part, index) in getParts($t('cookies.content_2-1'))" :key="index">
                        <a class="linkText" v-if="part.type === 'link'" href="#" @click.prevent="navigate(part.name)">{{ part.text }}</a>
                        <span v-else>{{ part.text }}</span>
                    </span>
                    <div class="cookies-switches">

                            <div class="form-switch" v-for="(cookie, key) in cookies" :key="key">
                                <div class="d-flex flex-column fields">
                                    <div class="d-flex flex-row field-row justify-content-between">
                                        <div class="d-flex flex-row align-items-center">
                                            <label class="kpi-label" v-html="cookie.label"></label>
											<span
                                                v-if="showMore"
                                                v-popoverr 
                                                data-bs-toggle="popover" 
                                                data-bs-trigger="hover"
                                                :data-bs-content="cookie.description"
                                                data-bs-placement="right"
                                                data-bs-html="false">
                                                <font-awesome-icon icon="fa-regular fa-circle-question"/>
                                            </span>                                            
											<!-- <YBtn 
                                                v-tooltip="{ text: cookie.description, color: 'primary' }"
                                                size="mini"
                                                fab
                                                color="primary" 
                                                outline
                                            ><font-awesome-icon icon="fa-regular fa-circle-question"/></YBtn> -->
                                        </div>
                                        <div class="configuration-kpi-switch form-switch">
                                            <!-- <input 
                                                type="checkbox" 
                                                class="form-check-input" 
                                                role="switch"
                                                v-model="cookie.value"
                                                :id="key"
                                                :disabled="cookie.disabled"> -->
                                                <MazSwitch
                                                    v-model="cookie.value"
                                                    color="primary"
                                                    :id="key"
                                                    @change="emit('changeInput', notificationValue ? 1 : 0, 'notifications')"
                                                >
                                                {{ $t('notifications.allowNotifications') }}
                                                </MazSwitch>
                                        </div>
                                    </div>
                                </div>
                            </div>

                    </div>
                </div>
            </template>
            <template #footer>
                <div class="cookies-first-step" v-show="!showMore">
                    <YBtn 
                        size="lg"
                        color="transparent" 
                        :label="$t('field.accept')+' cookies'"
                        :rounded="true"
                        roundedSize="full"
                        type="button"
                        class="yauty-button"
                        variant="link"
                        noUnderline
                        contentClass="underline-animation"
                        @click="acceptCookies">
                    </YBtn>                      
                    <YBtn 
                        size="lg"
                        color="transparent" 
                        :label="$t('field.reject')+' cookies'"
                        :rounded="true"
                        roundedSize="full"
                        type="button"
                        class="yauty-button"
                        variant="link"
                        noUnderline
                        contentClass="underline-animation"
                        @click="closeModal">
                    </YBtn>                  
                    <YBtn 
                        size="lg"
                        color="transparent" 
                        :label="$t('field.more_options')"
                        :rounded="true"
                        roundedSize="full"
                        type="button"
                        class="yauty-button"
                        variant="link"
                        noUnderline
                        contentClass="underline-animation"
                        @click="showMoreInfo(true)">
                    </YBtn>
                </div>
                <div class="cookies-second-step" v-show="showMore">
                    <YBtn 
                        size="md"
                        color="transparent" 
                        :label="$t('field.save')"
                        :rounded="true"
                        roundedSize="full"
                        type="button"
                        class="yauty-button"
                        variant="link"
                        noUnderline
                        contentClass="underline-animation"
                        @click="acceptCookiesSelection">
                    </YBtn>   
                    <YBtn 
                        size="md"
                        color="transparent" 
                        :label="$t('appointment.back')"
                        :rounded="true"
                        roundedSize="full"
                        type="button"
                        class="yauty-button"
                        variant="link"
                        noUnderline
                        contentClass="underline-animation"
                        @click="showMoreInfo(false)">
                    </YBtn>
                </div>
            </template>
        </MazDialog>
    </transition>
</template>

<style lang="scss">
.title-wrapper{
    gap: 20px;
}
.m-dialog-header{
    padding: 1rem;
    .cookies-modal-title{
        font-size: 22px;
    }
}
.cookies_modal .m-dialog-closebtn{
    display: none !important;
}
.m-dialog-content{
    a{
        color: white;
    }
    a:hover{
        color: white;
    }
    .cookies-first-step{
        font-size: 16px;
        letter-spacing: -0.4px;
    }
    .cookies-second-step{
        .cookies-switches{
            padding-top: 2rem;
            display: flex;
            flex-direction: column;
            gap: 0.5rem;
            .field-row{
                padding-left: 0;
                padding-right: 28rem;
            }
            span{
                margin-left: 5px;
            }
            .form-switch{
                padding-left: 0 !important;
            }
        }
    }
}
.m-dialog-footer{
    padding: 3rem 1rem 1rem 1rem;
    .cookies-first-step,
    .cookies-second-step{
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: end;
        gap: 5px;
        width: 100%;
        button.m-btn.yauty-button {
            color: white !important;
            justify-content: end;
            width: 160px;
        }
    }
    .cookies-second-step{
        button.m-btn.yauty-button {
            width: 120px;
        }
    }
}
</style>