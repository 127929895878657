import { library } from '@fortawesome/fontawesome-svg-core';

import {
  faBell,
  faCalendarDays,
  faEnvelope,
  faClipboard,
  faMoneyBill1,
  faMap,
  faCircleQuestion,
  faCircleDot
} from '@fortawesome/free-regular-svg-icons';

import {
  faArrowRight,
  faArrowLeft,
  faMagnifyingGlass,
  faHouse,
  faCarSide,
  faScrewdriverWrench,
  faList,
  faBorderAll,
  faTableColumns,
  faCar,
  faUser,
  faKey,
  faChevronRight,
  faChevronLeft,
  faChevronDown,
  faWrench,
  faEllipsis,
  faArrowUpFromBracket,
  faCheck,
  faXmark,
  faFilePen,
  faFilePdf,
  faRotateLeft,
  faClipboardList,
  faGear,
  faUserPen,
  faHandshake,
  faExclamation,
  faHandHolding,
  faClipboardQuestion,
  faClipboardCheck,
  faCarTunnel,
  faBuildingCircleArrowRight,
  faArrowUpRightFromSquare,
  faFileCirclePlus,
  faFileCircleCheck,
  faFileCircleExclamation,
  faCircleExclamation,
  faPlugCirclePlus,
  faBoxArchive,
  faCalculator,
  faAnglesRight,
  faAnglesLeft,
  faArrowDownShortWide,
  faArrowUpShortWide,
  faCircleXmark,
  faColumns,
  faEllipsisVertical
} from '@fortawesome/free-solid-svg-icons';

library.add({
  faArrowDownShortWide,
  faArrowUpShortWide,
  faArrowRight,
  faArrowLeft,
  faBell,
  faMagnifyingGlass,
  faHouse,
  faCarSide,
  faScrewdriverWrench,
  faCalendarDays,
  faList,
  faBorderAll,
  faTableColumns,
  faCar,
  faUser,
  faEnvelope,
  faKey,
  faChevronRight,
  faChevronLeft,
  faChevronDown,
  faWrench,
  faClipboard,
  faEllipsis,
  faArrowUpFromBracket,
  faFilePen,
  faFilePdf,
  faMoneyBill1,
  faMap,
  faCheck,
  faXmark,
  faRotateLeft,
  faClipboardList,
  faCircleQuestion,
  faGear,
  faUserPen,
  faHandshake,
  faExclamation,
  faHandHolding,
  faClipboardQuestion,
  faClipboardCheck,
  faCarTunnel,
  faBuildingCircleArrowRight,
  faArrowUpRightFromSquare,
  faFileCirclePlus,
  faFileCircleCheck,
  faFileCircleExclamation,
  faCircleExclamation,
  faPlugCirclePlus,
  faBoxArchive,
  faCircleDot,
  faCalculator,
  faAnglesRight,
  faAnglesLeft,
  faCircleXmark,
  faColumns,
  faEllipsisVertical
});
