<script setup>
 const contactMail = 'legal@cloudactivereception.com';
 const dpdMail = 'dpd@inforserveis.es';
</script>
<template>
    <section class="cookies-wrapper">
      <article class="cookies-title">
        {{ $t('cookies.title') }}
      </article>
      <article class="cookies-block">
        <div class="cookies-subtitle">
          {{ $t('cookies.text_1_title') }}
        </div>
        <div class="cookies-text-2">
          {{ $t('cookies.text_1_1') }}
        </div>
      </article>
      <article class="cookies-block">
        <div class="cookies-subtitle">
          {{ $t('cookies.text_2_title') }}
        </div>
        <div class="cookies-text-2">
          {{ $t('cookies.text_2_1') }}
        </div>
      </article>
      <article class="cookies-block">
        <div class="cookies-subtitle">
          {{ $t('cookies.text_3_title') }}
        </div>
        <div class="cookies-text-2">
          {{ $t('cookies.text_3_1') }}
        </div>
      </article>
      <article class="cookies-block">
        <div class="cookies-subtitle">
          {{ $t('cookies.text_4_title') }}
        </div>
        <div class="cookies-text-2">
          {{ $t('cookies.text_4_1') }}
        </div>
        <div class="cookies-text-2">
          {{ $t('cookies.text_4_2') }}
        </div>
        <div class="cookies-text-2">
          {{ $t('cookies.text_4_3') }}
        </div>
        <div class="cookies-text-1PL2">
          {{ $t('cookies.text_4_3_1') }}
        </div>
        <div class="cookies-text-1PL2">
          {{ $t('cookies.text_4_3_2') }}
        </div>
        <div class="cookies-text-1PL2">
          {{ $t('cookies.text_4_3_3') }}
        </div>
        <div class="cookies-text-1PL2">
          {{ $t('cookies.text_4_3_4') }}
        </div>
        <div class="cookies-text-2PL2">
          {{ $t('cookies.text_4_3_5') }}
        </div>
      </article>
      <article class="cookies-block">
        <div class="cookies-subtitle">
          {{ $t('cookies.text_5_title') }}
        </div>
        <div class="cookies-text-1">
          {{ $t('cookies.text_5_1') }}
        </div>
        <div class="cookies-text-2">
          {{ $t('cookies.text_5_2') }}
        </div>
        <div class="cookies-text-2">
          {{ $t('cookies.text_5_3') }}
        </div>
        <div class="cookies-text-1">
          {{ $t('cookies.text_5_3_1') }}
        </div>
        <div class="cookies-text-1">
          {{ $t('cookies.text_5_3_2') }}
        </div>
        <div class="cookies-text-1">
          {{ $t('cookies.text_5_3_3') }}
        </div>
        <div class="cookies-text-1">
          {{ $t('cookies.text_5_3_4') }}
        </div>
        <div class="cookies-text-1">
          {{ $t('cookies.text_5_3_5') }}
        </div>
        <div class="cookies-text-1">
          {{ $t('cookies.text_5_3_6') }}
        </div>
        <div class="cookies-text-1">
          {{ $t('cookies.text_5_3_7') }}
        </div>
      </article>
      <article class="cookies-block">
        <div class="cookies-subtitle">
          {{ $t('cookies.text_6_title') }}
        </div>
      </article>
      <article class="cookies-block">
        <div class="cookies-subtitle">
          {{ $t('cookies.text_7_title') }}
        </div>
        <div class="cookies-text-2">
          {{ $t('cookies.text_7_1') }}
        </div>
      </article>
      <article class="cookies-block">
          <div class="cookies-text-1">
            {{ $t('cookies.chrome') }}
          </div>
          <div class="cookies-text-1">
            {{ $t('cookies.chrome_menu') }}
          </div>
          <div class="cookies-text-2">
            <a :href="$t('cookies.chrome_url')" target="_blank">{{$t('cookies.chrome_url')}}</a>
          </div>
      </article>
      <article class="cookies-block">
          <div class="cookies-text-1">
            {{ $t('cookies.edge') }}
          </div>
          <div class="cookies-text-1">
            {{ $t('cookies.edge_menu') }}
          </div>
          <div class="cookies-text-2">
            <a :href="$t('cookies.edge_url')" target="_blank">{{$t('cookies.edge_url')}}</a>
          </div>
      </article>
      <article class="cookies-block">
          <div class="cookies-text-1">
            {{ $t('cookies.firefox') }}
          </div>
          <div class="cookies-text-1">
            {{ $t('cookies.firefox_menu') }}
          </div>
          <div class="cookies-text-2">
            <a :href="$t('cookies.firefox_url')" target="_blank">{{$t('cookies.firefox_url')}}</a>
          </div>
      </article>
      <article class="cookies-block">
          <div class="cookies-text-1">
            {{ $t('cookies.ios') }}
          </div>
          <div class="cookies-text-1">
            {{ $t('cookies.ios_menu') }}
          </div>
          <div class="cookies-text-2">
            <a :href="$t('cookies.ios_url')" target="_blank">{{$t('cookies.ios_url')}}</a>
          </div>
      </article>
      <article class="cookies-block">
        <div class="cookies-subtitle">
          {{ $t('cookies.text_8_title') }}
        </div>
        <div class="cookies-text-2">
          {{ $t('cookies.text_8_1') }}
        </div>
        <div class="cookies-text-2">
          {{ $t('cookies.text_8_2') }}
        </div>
        <div class="cookies-text-2">
          {{ $t('cookies.text_8_3') }}
        </div>
        <div class="cookies-text-2">
          {{ $t('cookies.text_8_4') }}
        </div>
        <div class="cookies-text-2">
          {{ $t('cookies.text_8_5') }}
        </div>
        <div class="cookies-text-2">
          {{ $t('cookies.text_8_6') }}
        </div>
        <div class="cookies-text-2">
          {{ $t('cookies.text_8_7') }}
        </div>
        <div class="cookies-text-2">
          {{ $t('cookies.text_8_8') }}
        </div>
      </article>
      <article class="cookies-block">
        <div class="cookies-subtitle">
          {{ $t('cookies.text_9_title') }}
        </div>
        <div class="cookies-text-2">
          {{ $t('cookies.text_9_1') }}
        </div>
        <div class="cookies-text-2">
          {{ $t('cookies.text_9_2') }}
        </div>
      </article>
      <article class="cookies-block">
        <div class="cookies-subtitle">
          {{ $t('cookies.text_10_title') }}
        </div>
      </article>
      <article class="cookies-block">
        <div class="cookies-subtitle">
          {{ $t('cookies.text_11_title') }}
        </div>
        <div class="cookies-text-2">
          {{ $t('cookies.text_11_1', {CONTACT_MAIL: dpdMail}) }}
        </div>
      </article>
      <article class="cookies-block">
        <div class="cookies-subtitle">
          {{ $t('cookies.text_12_title') }}
        </div>
        <div class="cookies-text-2">
          {{ $t('cookies.text_12_1') }}
        </div>
        <div class="cookies-text-2">
          {{ $t('cookies.text_12_2') }}
        </div>
      </article>
      <article class="cookies-block">
        <div class="cookies-subtitle">
          {{ $t('cookies.text_13_title') }}
        </div>
        <div class="cookies-text-2">
          {{ $t('cookies.text_13_1') }}
        </div>
      </article>
      <article class="cookies-block">
        <div class="cookies-subtitle">
          {{ $t('cookies.text_14_title') }}
        </div>
        <div class="cookies-text-2">
          {{ $t('cookies.text_14_1') }}
        </div>
      </article>
      <article class="cookies-block">
        <div class="cookies-subtitle">
          {{ $t('cookies.text_15_title') }}
        </div>
        <div class="cookies-text-2">
          {{ $t('cookies.text_15_1') }}
        </div>
      </article>
      <article class="cookies-block">
        <div class="cookies-subtitle">
          {{ $t('cookies.text_16_title') }}
        </div>
        <div class="cookies-text-2">
          {{ $t('cookies.text_16_1') }}
        </div>
      </article>
      <article class="cookies-block">
        <div class="cookies-subtitle">
          {{ $t('cookies.text_17_title') }}
        </div>
        <div class="cookies-text-2">
          {{ $t('cookies.text_17_1') }}
        </div>
      </article>      

    </section>
  </template>
  
  <style lang="scss" scoped>
  .cookies-wrapper {
    padding-top: 90px;
    padding-bottom: 230px;
    max-width: 990px;
    margin: 0 auto;
    .cookies-title {
      font-size: 60px;
      letter-spacing: -3px;
      font-family: 'CircularStd-Black';
      padding-bottom: 55px;
    }
    .cookies-block {
      font-size: 18px;
      letter-spacing: -0.9px;
      padding-bottom: 7px;
      .cookies-subtitle {
        font-size: 30px;
        letter-spacing: -1.5px;
        font-family: 'CircularStd-Bold';
        padding-bottom: 10px;
      }
      .cookies-text-1 {
        padding-bottom: 0px;
      }
      .cookies-text-2 {
        padding-bottom: 25px;
      }
      .cookies-text-3 {
        padding-bottom: 50px;
      }
      .cookies-text-1PL2{
        padding-left: 2rem;
      }
      .cookies-text-2PL2{
        padding-left: 2rem;
        padding-bottom: 25px;
      }
      a {
        color: #0047ba;
        text-decoration: none;
        position: relative;
      }
      a:before {
        content: '';
        position: absolute;
        width: 100%;
        height: 1px;
        border-radius: 4px;
        background-color: #0047ba;
        bottom: -2px;
        left: 0;
        transform-origin: right;
        transform: scaleX(0);
        transition: transform 0.3s ease-in-out;
      }
      a:hover:before {
        transform-origin: left;
        transform: scaleX(1);
      }
    }
  }
  </style>