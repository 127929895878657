<script setup>
import { ref, watch } from "vue";
import { useRouter } from 'vue-router';
import { useI18n } from 'vue-i18n';
import LocalStorage from '@/controller/LocalStorage.js';
import YBtn from "../../common/form/YBtn/YBtn.vue";
import WorkfileReparationTime from "./components/WorkfileReparationTime.vue";
import WorkfileActions from "./components/WorkfileActions.vue";
import WorkfileStatusList from "./components/WorkfileStatusList.vue";
import WorkfileVehicleLogo from "./components/WorkfileVehicleLogo.vue";
import WorkfileVehicleInfo from "./components/WorkfileVehicleInfo.vue";
import WorkfilesDates from "./components/WorkfilesDates.vue";
import WorkfilesClient from "./components/WorkfilesClient.vue";
import WorkfilesBranch from "./components/WorkfilesBranch.vue";
const back = '/assets/workfiles/back.svg';
const props = defineProps({
  workfile: Object
});

const userColor = ref(LocalStorage.getColor());
const { t } = useI18n();
const show = ref(false);

watch(() => props.workfile, () => {
  if(props.workfile){
    show.value = true;
  }
});
</script>

<template>
    <div class="workfile-body-wrapper" v-if="show">
        <div class="navigation-wrapper">
            <YBtn class="navigation" @click="$router.go(-1)" variant="link" :noUnderline="true" contentClass="navigation">
                <img :src="back" alt="" style="height:1rem;">
                <span>{{ $t('field.back') }}</span>
            </YBtn>
        </div>
        <div class="workfile-body">
            <div class="workfile-vehicle-logo">
                <WorkfileVehicleLogo :vehicle="props.workfile?.vehicle"/>
            </div>
            <div class="workfile-vehicle-info">
                <WorkfileVehicleInfo :vehicle="props.workfile?.vehicle" :or="props.workfile?.orNumber"/>
            </div>
            <div class="workfile-dates">
                <WorkfilesDates 
                    :modified="props.workfile?.date"
                    :schedule="props.workfile?.scheduleDate" 
                    :reparation="props.workfile?.reparationDate" 
                    :delivery="props.workfile?.deliveryDate" 
                    :type="props.workfile?.type"
                    :reparationTime="props.workfile?.reparationTime"/>
            </div>
            <div class="workfile-client">
                <WorkfilesClient :client="props.workfile?.client"/>
            </div>
            <div class="workfile-branch">
                <WorkfilesBranch :branch="props.workfile?.data?.branch"/>
            </div>
            <div class="workfile-actions-wrapper">
                <WorkfileActions :actions="props.workfile?.actions" :outOfStock="props.workfile?.outOfStock"/>
            </div>
            <div class="workfile-reparation-time-wrapper">
                <WorkfileReparationTime :time="props.workfile?.reparationTime" :statusLog="props.workfile?.data?.workfile_status_log" :type="props.workfile?.type"/>
            </div>
            <div class="workfile-status-line-wrapper">
                <WorkfileStatusList :log="props.workfile?.data?.workfile_status_log" :documents="props.workfile?.data?.documents" :type="props.workfile?.type"/>
            </div>
        </div>
    </div>
</template>

<style lang="scss">
.navigation-wrapper{
    display: flex;
    justify-content: start;
    align-items: center;
    padding-bottom: 5px;
    height: 40px;
    padding-left: 10px;
    .navigation{
        display: flex;
        gap: 1rem;
        align-items: center;
        & *{
            color: #7E8E94;
        }
        padding-bottom: 0;
        width: fit-content;
    }
    .navigation:hover{
        cursor: pointer;
        img {
            animation: arrow 700ms linear infinite;
        }
    }
}
@keyframes arrow {
    0% {right: 0px}
    50% {right: 10px}
    100% {right: 0px}
}
.workfile-body{
    border-radius: 25px;
    display: grid;
    flex-direction: row;
    background-color: white;
    padding-bottom: 2rem;
    padding-right: 3rem;
    @media only screen and (max-width: 720px) {
        grid-template-columns: 130px 1fr;
        grid-template-rows: 50px 1fr;
        grid-template-areas: 
        "reparation-time  reparation-time"
        "vehicle-logo     vehicle-info" 
        "client           client"
        "branch           branch"
        "dates           dates"
        "actions          actions"
        "status-line      status-line";
    }
    @media only screen and (min-width: 721px) {
        grid-template-columns: 1fr 300px;
        grid-template-rows: 50px 1fr;
        grid-template-areas: 
        ".  reparation-time"
        "vehicle-logo     vehicle-info"
        "dates            dates" 
        "client           status-line"
        "branch           status-line"
        "actions          status-line";
    }
    @media only screen and (min-width: 1280px) {
        grid-template-columns: 170px 170px 250px 1fr 1fr 350px;
        grid-template-rows: 50px 110px 1fr 50px;
        grid-template-areas: 
        ".            .            .            .            .            reparation-time" 
        "vehicle-logo vehicle-info dates        client       branch       status-line"    
        "actions      actions      actions      actions      actions      status-line"
        ".            .            .            subtotal     subtotal     status-line";
    }
    @media only screen and (min-width: 1680px) {
        grid-template-columns: 170px 200px 250px 1fr 1fr 350px;
    }


    .workfile-navigation-wrapper{
        grid-area: navigation;
        justify-self: end;
        align-self: start;
        padding-top: 1rem;

    }
    .workfile-navigation-wrapper:hover{
        cursor: pointer;
    }
    .workfile-info-wrapper{
        grid-area: general-info;
    }
    .workfile-actions-wrapper{ 
        grid-area: actions;
    }
    .workfile-reparation-time-wrapper{
        grid-area: reparation-time;
    }
    .workfile-status-line-wrapper{
        grid-area: status-line;
    }
    .workfile-actions-total-wrapper{
        grid-area: subtotal;
    }
    .workfile-vehicle-logo{
        grid-area: vehicle-logo;
    }
    .workfile-vehicle-info{
        grid-area: vehicle-info;
    }
    .workfile-dates{
        grid-area: dates;
        margin-right: 30px;
        margin-top: 3px;
    }
    .workfile-client{
        grid-area: client;
        right: 20px;
    }
    .workfile-branch{
        grid-area: branch;
    }
}
</style>