<script setup>
import MazLoadingBar from 'maz-ui/components/MazLoadingBar';
import { computed } from 'vue';

const props = defineProps({
    color: {
        type: String,
        default: 'primary' //'primary', 'secondary', 'info', 'success', 'warning', 'danger', 'white', 'black'
    },
    height: {
        type: String,
        default: '0.125rem'
    },
    size: {
        type: String,
        default: 'xl' //'xs', 'sm', 'md', 'lg', 'xl'
    },
})

const width = computed(() => {
    if (props.size === 'xs') {
        return '20%';
    }
    else if (props.size === 'sm') {
        return '40%';
    }
    else if (props.size === 'md') {
        return '60%';
    }
    else if (props.size === 'lg') {
        return '80%';
    }
    else {
        return '100%';
    }
})
</script>
<template>
    <MazLoadingBar
        :color="props.color"
        :height="props.height"
        :style="'width: ' + width"
    ></MazLoadingBar>
</template>