<script setup>
import { defineProps, ref, watch } from "vue";
import { useI18n } from "vue-i18n";
import { storeToRefs } from "pinia";
import Separator from "@/components/common/utils/Separator.vue";
import NotificationsCards from "@/components/notifications/NotificationsCards.vue";
import { useNotificationStore } from '@/stores/notification.js';
import { notificationsReadAll } from '@/services/api/Notifications.js';
import LocalStorage from '@/controller/LocalStorage.js';
import YLoader from "../loaders/YLoader.vue";

const { t } = useI18n();
const notificationStore = useNotificationStore();
const notifications = storeToRefs(notificationStore);
const { getNotifications: notificationList, getNotificationsUnread: notificationUnreadList,} = storeToRefs(notifications);
const showUnread = ref(true);
const notificationsFilteredList = ref();
const userKey = ref(LocalStorage.getKey());
const loading = ref(false);
const props = defineProps({
  class: String,
  show: Boolean,
});
const emit = defineEmits(["loading"]);

function getNotifications(){
    return showUnread.value ? notificationList.value.filter((notification)=>notification.read == 0)
                                    : notificationList.value;
}

function filterNotifications(){
    notificationsFilteredList.value = getNotifications();
}

function readAll(){
    loading.value = true;
    let ids = notificationList.value.map((notification)=>notification.id);
    notificationsReadAll({k: userKey.value, notifications: ids}).then((res)=>{
    }).then(()=>{
        loading.value = false;
        notificationStore.fetchAll({k: userKey.value});
    }).catch((err)=>{
        console.log('err',err);
    });
}


</script>

<template>
  <div v-if="props.show" class="modal__template" :class="[(props.class ?? '')]">
      <div class="modal-wrapper" @click="$emit('closeModal')">
        <div class="modal-container" @click.stop="">
          <div class="modal-header">
            <div class="modal-header-title">
                {{ t('field.notifications') }}
            </div>
            <div class="actions">
                <div class="show-read">
                    <span class="show-read-text light-grey">{{$t("notifications.showUnread")}}</span>
                    <div class="show-read-switch form-switch">
                        <input 
                            type="checkbox" 
                            class="form-check-input" 
                            role="switch"
                            id="show-read" 
                            v-model="showUnread"
                            @change="filterNotifications">
                    </div>
                </div>
                <div class="show-in-view">
                    <router-link to="/notifications" class="btn-header-route" title="Mostrar en pantalla completa">
                        <font-awesome-icon icon="fa-solid fa-arrow-up-right-from-square" />
                    </router-link>
                </div>
            </div>
          </div>

          <div class="modal-body">
            <Separator />
            <div class="modal-body-actions">
                <span class="mark-all-read light-grey" @click="readAll()" v-if="!loading">{{$t("notifications.readAll")}}</span>
                <div class="invitation__content_loader" v-if="loading">
                    <YLoader />
                </div>  
            </div>
            <div class="notifications-list">
                <NotificationsCards :notifications="showUnread ? notificationUnreadList : notificationList"/>
            </div>
          </div>

          <div class="modal-footer">
            <slot name="footer"> </slot>
          </div>
        </div>
      </div>
  </div>
</template>

<style lang="scss" scoped>
@import "@/styles/colors.scss";
.modal-wrapper{
    font-family: 'CircularStd-Book';
}
.modal-header{
    .actions, .show-read{
        display: flex;
        align-items: center;
    }
    .show-read{
        gap: 0.5rem;
    }
    .show-read-text{
        font-size: 12px;
    }
    .modal-header-title h3{
        font-weight: 600;
    }
    .actions{
        gap: 1rem;
    }
    .modal-header-title{
        font-weight: bold;
        font-size: 22px;
    }
}
.modal-body{
    .modal-body-actions{
        margin-top: 10px;
        display: flex;
        justify-content: end;
        font-size: 11px;
        .mark-all-read{
            margin-bottom: 0.5rem;
        }
        .mark-all-read:hover{
            cursor: pointer;
            text-decoration: underline;
        }
    }
}
.modal__template{
    position: absolute;
    top: 60px;
    right: 98px;
    z-index: 2
}
.modal-container {
  width: 500px;
  height: 600px;
  margin: 0px auto;
  padding: 20px 35px;
  background-color: #fff;
  border-radius: 2px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.33);
  transition: all 0.3s ease;
}

.modal-header  {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 10px;
}

.modal-body,
.modal-footer {
  margin: 5px 0 15px;
}

.modal-enter-from,
.modal-leave-to {
  opacity: 0;
}

.modal-enter-active .modal-container,
.modal-leave-active .modal-container {
  -webkit-transform: scale(1.1);
  transform: scale(1.1);
}
.notifications-list{
    padding-bottom: 1rem;
    height: 500px;
    overflow-y: auto;
    margin-right: -25px;
}
.light-grey{
    color: $light-grey-descriptions;
    font-weight: 600;
}
::-webkit-scrollbar {
  width: 6px;
}
::-webkit-scrollbar-thumb {
  background-color: #AAAAAA;
}
.invitation__content_loader{
    position: relative;
    .custom-loader{
        margin-left: 10px;
        font-size: 10px;
    }
}
</style>
