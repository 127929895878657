import { ref } from "vue";
import LocalStorage from '@/controller/LocalStorage.js';
import { hexToHSL, hslAdjust } from "@/services/api/Themes.js";

export function setBranding() {
    const branding = ref(LocalStorage.getBranding());
    const rootStyle = document.documentElement.style;
    if(branding.value && branding.value.color){
        rootStyle.setProperty('--primary-color', hexToHSL(branding.value.color));
        rootStyle.setProperty('--primary-color-hover', hslAdjust(branding.value.color, -10, -19));
        rootStyle.setProperty('--primary-color-50', hslAdjust(branding.value.color, -10, -5)); //210deg 100% 56% / 20%
    }

    if(branding.value && branding.value.secondary_color){
        rootStyle.setProperty('--secondary-color', hexToHSL(branding.value.secondary_color));
        rootStyle.setProperty('--secondary-color-hover', hslAdjust(branding.value.secondary_color, -10, -5));
    }

    if(branding.value && branding.value.tertiary_color){
        rootStyle.setProperty('--tertiary_color', hexToHSL(branding.value.tertiary_color));
        rootStyle.setProperty('--tertiary-color-hover', hslAdjust(branding.value.secondary_color, -10, -5));
    }

    rootStyle.setProperty('--info-color', hexToHSL('#EBEBEB'));
    rootStyle.setProperty('--info-color-hover', hslAdjust('#EBEBEB', -10, -5));
}
