<script setup>
import Input from '@/stories/Inputs/generic/Input.vue';
import { useI18n } from 'vue-i18n';

const { t } = useI18n();
const props = defineProps({
    position: String, //left, center, right
    width: Number,
    enabled: Boolean
});

const inputWidth = '200px';
let inputPosition = undefined;
if(props.position == undefined || props.position == 'left'){
    inputPosition = 'start';
}else if(props.position == 'center'){
    inputPosition = 'center';
}else if(props.position == 'right'){
    inputPosition = 'end';
}

const emit = defineEmits(["searching"]);
let searchText = '';
function search() {
  emit('searching', searchText);
}
</script>

<template>
    <div class="custom-table-search">
        <Input
            type="text"
            :placeholder="t('field.search')"
            input="search"
            size="large"
            v-model="searchText"
            @keyup="search"
        />
    </div>
</template>

<style lang="scss" scoped>
    .custom-table-search{
        display: flex;
        width: 100%;
        align-self: center;
        justify-content: v-bind(inputPosition);
        padding-bottom: 0.5rem;
    }
    input {
        max-width: v-bind(inputWidth);
    }
</style>