<script setup>
import { ref, onMounted } from "vue";
import NotificationCard from "@/components/notifications/NotificationCard.vue";
import LocalStorage from '@/controller/LocalStorage.js';
import { useNotificationStore } from '@/stores/notification.js';
import { notificationsReadAll } from '@/services/api/Notifications.js';

const notificationStore = useNotificationStore();
const userKey = ref(LocalStorage.getKey());
const readOneLoader = ref(false);
let loadingIds = ref({});

const props = defineProps({
    notifications: Object,
});

function readOne(id){

    readOneLoader.value = true;
    loadingIds.value[id] = true;
    notificationsReadAll({k: userKey.value, notifications: Object.keys(loadingIds.value)}).then((res)=>{
        loadingIds.value[id] = false;
        readOneLoader.value = false;
    }).then(()=>{
        notificationStore.fetchAll({k: userKey.value}).then((res)=>{

        }).catch((err)=>{
            console.log('err fetchall',err);
        });
    }).catch((err)=>{
        console.log('err',err);
    })
}

</script>

<template>
    <div class="notifications-cards-wrapper">
        <NotificationCard :notification="notification" v-for="notification in props.notifications" :loading="loadingIds[notification.id]" @readOne="(id)=>readOne(id)"/>
    </div>
</template>

<styles lang="scss" scoped>
.notifications-cards-wrapper{
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
}
</styles>