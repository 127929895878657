<script setup>
import { ref } from "vue";
import { RouterView, useRouter } from "vue-router";

import { useSidenavbarStore } from "@/stores/sidenavbar.js";

const sidenavbarStore = useSidenavbarStore();
const router = useRouter();

const pathName =  router.currentRoute.value.name;

let yautyData = ref('');
if (localStorage.getItem('yautyData')) {
  yautyData = JSON.parse(localStorage.getItem('yautyData'));
}
const emit = defineEmits(["animateSidebar"]);
function animateSidebar() {
  emit("animateSidebar");
}

</script>

<template>
  <div
    class="app__content"
    v-bind:class="[(yautyData.name && pathName != 'invitations' && pathName != 'activate') ? 'wrapper' : 'no-wrapper']"
    :class="[sidenavbarStore.isCollapse ? '' : 'extended-wrapper']"
    :key="$route.fullPath"
    @callback="handleCallback"
  >
    <RouterView :key="$route.fullPath" @animateSidebar="animateSidebar"/>
  </div>
</template>

<style lang="scss">
  @import 'src/styles/colors.scss';
.wrapper {
  padding-left: 5dvw;
  min-height: 91.45dvh;
  transition: 0.7s;
  background-color: $layout-background;
}

.no-wrapper {
  min-height: calc(100vh - 60px);
}

.extended-wrapper {
  padding-left: 210px;
  transition: 0.7s;
}
</style>
