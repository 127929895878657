<script setup>
import { watch } from "vue";

const props = defineProps({
  vehicle: Object,
  or: String
});

watch(() => props.vehicle, () => {

});
</script>

<template>
    <div class="workfile-vehicle-info">
        <div class="workfile-or" 
          v-if="props.or"          
          v-popoverr 
          data-bs-toggle="popover" 
          data-bs-trigger="hover"
          :data-bs-content="props.or"
          data-bs-placement="top"
          data-bs-html="false"
          >
            <span>Nº {{ props.or }}</span>
        </div>
        <div class="workfile-vehicle-title">
            <span class="bold">{{ props.vehicle?.brand + ' ' + props.vehicle?.model }}</span>
        </div>
        <div class="workfile-vehicle-content">
            <div class="workfile-vehicle-content-item plate">
                <span class="bold">{{ props.vehicle?.plate }}</span>
            </div>
            <div class="workfile-vehicle-content-item vin">
                <span>{{  props.vehicle?.vin }}</span>
            </div>
            <div class="workfile-vehicle-content-item">
                <span>{{ props.vehicle?.mileage }}</span>
            </div>
        </div>
    </div>
</template>

<style lang="scss" scoped>
.workfile-vehicle-info{
  @media only screen and (max-width: 1279px) {
    padding-left: 3rem;
    margin-bottom: 0.5rem;
  }
  padding-top: 5px;
  .workfile-or{
    background-color: #363636;
    border-radius: 15px;
    color: white;
    padding: 2px 15px;
    max-width:165px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    width: fit-content;
    margin-bottom: 10px;
  }
  .workfile-or span{
    max-width: 175px;
  }
  .workfile-vehicle-title{
    font-size: 16px;
    padding-bottom: 5px;
  }
  .bold{
    font-weight: bold;
  }
  .workfile-vehicle-title,
  .workfile-vehicle-content{
    line-height: 1.3;
  }
  .plate{
    font-size: 16px;
  }
  .vin{
    font-size: 14px;
  }
}
</style>