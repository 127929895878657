<script>
import RegisterForm from '@/components/register/new/RegisterForm.vue';

export default {
  data() {
    return {
      imgYautyPath: "../public/assets/login/login_logo.png",
      imgLogginPath: '../public/assets/login/login.png',
    };
  },
  components: {
    RegisterForm,
  },
};
</script>

<template>
  <section class="register__container">
    <RegisterForm />
  </section>
</template>

<style lang="scss" scoped>
</style>
