import { defineStore, acceptHMRUpdate } from 'pinia';

export const useCommunicatorStore = defineStore({
    id: "communicator",
	state: () => ({ 
        localStorage: new Date().getTime(),
        brandingLocalStorage: new Date().getTime(),
        sessionExpired: false,
        selectdedUser: null,
    }),
    getters: {
        localStorageLastChange(state) {
            return state.localStorage;
        },
        getLastBrandingChanges(state) {
            return state.brandingLocalStorage;
        },
        sessionHasExpired(state) {
            return state.sessionExpired;
        },
        getSelectedUser(state) {
            return state.selectdedUser;
        }
    },
    actions: {
        localStorageChanged() {
            this.localStorage = new Date().getTime();
        },
        brandingLocalStorageChanged() {
            this.brandingLocalStorage = new Date().getTime();
        },
        toggleSessionExpiration(){
            this.sessionExpired = !this.sessionExpired;
        },
        setSelectedUser(user){
            this.selectdedUser = user;
        },
        resetSessionExpiration(){
            this.sessionExpired = false;
        }
    }
});

if (import.meta.hot) {
    import.meta.hot.accept(acceptHMRUpdate(useCommunicatorStore, import.meta.hot))
}