import { defineStore, acceptHMRUpdate } from 'pinia'
import { url } from '@/conf/conf.js';
import { get } from '@vueuse/core';

export const useGlobalStore = defineStore({
    id: "global",
	state: () => ({ 
        sideNavKey: 999,
        currentView: '',
    }),
    getters: { 
        getSideNavKey(state){
            return state.sideNavKey;
        },
        getCurrentView(state){
            return state.currentView;
        }       
    },
    actions: {
        resetSideNavKey(){
            this.sideNavKey = Math.floor(Math.random()*100000000);
        },
        setCurrentView(view){
            this.currentView = view;
        }
    }
});

if (import.meta.hot) {
    import.meta.hot.accept(acceptHMRUpdate(useGlobalStore, import.meta.hot))
}