import { useUserPermission } from '@/stores/userPermission.js';
import { storeToRefs } from 'pinia';
import { ref } from "vue";
import LocalStorage from "@/controller/LocalStorage.js";
const organizationId = ref(LocalStorage.getOrganizationId());
export function isAuthorized(key) {
    const userPermissionStore = useUserPermission();
    const userPermission = storeToRefs(userPermissionStore);
    const { 
        getOrganizationPermissions:     organizationPermissions, 
        getPermissionsUser:             userPermissions, 
        getPermissionsVehicle:          vehiclePermissions, 
        getPermissionsWorkfile:         workfilePermissions, 
        getPermissionsYauty:            yautyPermissions
    } = storeToRefs(userPermission);

    const permissionsList = {
        yautyadmin:                 yautyPermissions.value['admin'],

        manageOrganization:         organizationPermissions.value['manage'],
        managePermissions:          organizationPermissions.value['userPermissions'],
        manageClients:              organizationPermissions.value['userClients'],
        manageVehicles:             organizationPermissions.value['userVehicles'],
        userCreate:                 organizationPermissions.value['userCreate'],

        createVehicle:              vehiclePermissions.value['create'],
        updateVehicle:              vehiclePermissions.value['update'],
        validateWorkfile:           workfilePermissions.value['validate'],
        createAppointment:          workfilePermissions.value['appointment_create'],

        organizationMenu:           organizationMenuAccess(),
        organizationUsersManage:    organizationUsersAccess(),
    };
    
    return permissionsList[key] != undefined;

    function organizationMenuAccess(){
        return  organizationPermissions.value['manage'] ||
                organizationPermissions.value['userPermissions'] ||
                organizationPermissions.value['userClients'] ||
                organizationPermissions.value['userVehicles'] ||
                organizationPermissions.value['userCreate'] ||
                (yautyPermissions.value['admin']) ;
    }
    function organizationUsersAccess(){
        return  organizationPermissions.value['userPermissions'] ||
                organizationPermissions.value['userClients'] ||
                yautyPermissions.value['admin'];
    }
}
