import { defineStore, acceptHMRUpdate } from 'pinia'
import { url } from '@/conf/conf.js';

export const useUserStore = defineStore({
    id: "user",
	state: () => ({ 
        userData: [],
        isLoading: true
    }),
    getters: { 
        getUser(state){
            return state.userData
        }       
    },
    actions: {
        async fetchLoginUser(params) {
            try {

                return await fetch(url,
                    {
                        method: 'POST',
                        headers: {
                            'Accept': 'application/json',
                            'Content-Type': 'application/json'
                        },
                        body: JSON.stringify({ 
                            user_email: params.email, 
                            user_password: params.password, 
                            method: "loginUser"
                        })
                    })
                    .then((response) => response.json())
                    .then((data) => {
                        this.userData = data;
                        return data
                    })
                    .catch((error) => {
                        console.error("Error:", error);
                    }
                );
            }
            catch (error) {
                console.log(error)
            }
        },        
    }
});

// by now at least
if (import.meta.hot) {
    import.meta.hot.accept(acceptHMRUpdate(useUserStore, import.meta.hot))
}