import { url } from '@/conf/conf.js';
import LocalStorage from '@/controller/LocalStorage.js';
import { useRouter } from "vue-router";
const middlewareFunctions = {
  isTokenAlive: () => {
    if(LocalStorage.tokenHasExpired()){
      console.log('Token has expired. Redirecting to login page. Plesae login again.');
      const router = useRouter();
      if(router) router.push("/logout");
    }
    else{
      return true;
    }
  },
  isAuth: () => {}
};

export function middleware(url, options, middlewares) {
  
  return async function() {
      if(middlewares.length) {
        middlewares.forEach(func => {
          middlewareFunctions[func]();
        });
      }
      return fetch(url, options);
  }
}
