<script setup>
import { fab } from '@fortawesome/free-brands-svg-icons';
import MazBtn from 'maz-ui/components/MazBtn';
import { onMounted, computed } from 'vue';

const props = defineProps({
    type:{
        type: String, //'submit' | 'reset' | 'button'
        default: 'button'
    },
    size: {
        type: String,
        default: 'md' //'mini', 'xs', 'sm', 'md', 'lg', 'xl'
    },
    color: {
        type: String,
        default: 'primary' //'primary', 'secondary', 'info', 'success', 'warning', 'danger', 'white', 'black', 'transparent', 'theme'
    },
    loading: {
        type: Boolean, //el color l'agafa de :color
        default: false
    },
    outline: {
        type: Boolean, //el color l'agafa de :color
        default: false
    },
    pastel: {
        type: Boolean, //el color l'agafa de :color
        default: false
    },
    rounded: {
        type: Boolean, //full border radius
        default: true
    },
    roundedSize: {
        type: String, //'xs', 'sm', 'md', 'lg', 'xl', 'full'
        default: 'md'
    },
    noRounded: {
        type: Boolean,
        default: false
    },
    fab: {
        type: Boolean, // icon l'agafa de :icon i el tamany de :size
        default: false
    },
    icon: {
        type: String,
        default: ''
    },
    leftIcon: {
        type: String,
        default: ''
    },
    rightIcon: {
        type: String,
        default: ''
    },
    block: {
        type: Boolean, //agafara un width 100%
        default: false
    },
    disabled: {
        type: Boolean,
        default: false
    },
    noUnderline: {
        type: Boolean, //elimina underline en fer hover quant la variant es link
        default: false
    },
    href: {
        type: String,
        default: ''
    },
    to: {
        type: String,
        default: ''
    },
    noPadding: {
        type: Boolean,
        default: false
    },
    noElevation: {
        type: Boolean, // elimina el box shadow
        default: false
    },
    contentClass: {
        type: String, // classes a afegir al content wrapper
        default: ''
    },
    label: {
        type: String,
        default: ''
    },
    variant: {
        type: String, // si es true el component es renderitzara com a link
        default: 'button'
    }
})
const to = computed(() => {
      // some logic here
      return props.to ? { path: props.to } : null
    })
</script>

<template>
    <MazBtn 
        :type="props.type"
        :size="props.size"
        :color="props.color"
        :loading="props.loading"
        :outline="props.outline"
        :pastel="props.pastel"
        :fab="props.fab"
        :block="props.block"
        :disabled="props.disabled"
        :noUnderline="props.noUnderline"
        :href="props.href"
        :target="props.href ? '_blank' : ''"
        :to="to"
        :rounded="props.rounded"
        :roundedSize="props.roundedSize"
        :noRounded="props.noRounded"
        :icon="props.icon"
        :leftIcon="props.leftIcon"
        :rightIcon="props.rightIcon"
        :noPadding="props.noPadding"
        :noElevation="props.noElevation"
        :contentClass="props.contentClass"
        :variant="props.variant == 'link' ? 'link' : 'button'"
    >
        {{ props.label }}
        <slot></slot>
     </MazBtn>
</template>

<style lang="scss" scoped>

</style>