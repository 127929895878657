<script setup>
import { ref } from "vue";
import { useI18n } from 'vue-i18n';
import { useRouter } from "vue-router";
import Error from "@/components/common/errors/Error.vue";
import Oks from "@/components/common/oks/Ok.vue";
import YLoader from "../common/loaders/YLoader.vue";
import logo from '../../../public/assets/logo-yauty-color-2.png';
const imgYautyPath = "../public/assets/login/login_logo.svg";
const imgLogginPath = '../public/assets/login/road_web.jpg';
import { activateRegister } from '@/services/api/Activate.js';
import { getUserBranding } from '@/services/api/Users.js';
import YBtn from '@/components/common/form/YBtn/YBtn.vue';
const { t } = useI18n();
const router = useRouter();
const invitationLoader = ref(true);
const loaderButton = ref(false);
const disabledButton = ref(false);
const errorMsg = ref(false)
const showError = ref(false);
const activateOk = ref(false);
const okMsg = ref('');
const showOk = ref(false);
const userLogo = ref(logo);
const userColor = ref("");

let path = router.currentRoute.value.params.id
let brandingData = ref(false)

getUserBranding({
    path: path,
})
.then((res) => {
   invitationLoader.value = false;
    if (res) {
        if(res.logo && res.logo != '') {
            userLogo.value = res.logo;
        }
        if(res.color && res.color != '') {
            userColor.value = res.color;
        }
        return res.data;
    } 
return 0;
})
.catch((err) => {
    console.log(err);
});

function sendAcceptInvitation() {
    showError.value = false;
    errorMsg.value = "";
    loaderButton.value = true;
    disabledButton.value = "disabled";

    activateRegister({
        id: path
    }).then((res) => {
        loaderButton.value = false;
        disabledButton.value = false;
        if(res && res.errors) {
            showError.value = true;
            if(res.errors[0].error == 'DOES_NOT_EXISTS') {
                errorMsg.value =  t('error.INVITATION_DOES_NOT_EXISTS');
            }
            if(res.errors[0].error == 'ALREADY_ACTIVE') {
                errorMsg.value =  t('error.ALREADY_ACTIVE');
            }
        } else {
            showError.value = false;
            activateOk.value = true;
            okMsg.value = t('activate.activate_ok');
            setTimeout(() => {
                showOk.value = false;
                router.push("/");
            }, 3000);
        }
        
    return 0;
    })
    .catch((err) => {
        console.log(err);
    });
}
</script>

<template>
    <section class="register-container">
        <div class="register-section--left">
        <img :src="imgLogginPath" />
        </div>
        <div class="register-section--right">
            <div class="register-logo">
                <img :src="imgYautyPath" />
            </div>
            <div class="register-message_1">
                {{ t('activate.done_msg') }}
            </div>
            <div class="register-message_2">
                <span>
                    {{  t('activate.activate_msg1') }} <br>
                </span>
                <span>
                    {{  t('activate.activate_msg2') }}
                </span>
            </div>
            <div class="register-form">
                <div class="invitation__loader" v-if="invitationLoader">
                    <YLoader />
                </div>
                <div v-else>
                    <div class="activate__btns">
                        <YBtn 
                            size="sm"
                            color="primary" 
                            :loading="loaderButton"
                            :disabled="disabledButton"
                            :label="t('activate.activate_btn')"
                            :rounded="true"
                            roundedSize="full"
                            type="button"
                            class="yauty-button"
                            @click="sendAcceptInvitation">
                        </YBtn>                    
                    </div>
                    <div class="error-container mt-3" v-if="showError">
                        <Error :text="errorMsg" size="small" />
                    </div>
                    <div class="activate-ok mt-3" v-if="activateOk">
                        <Oks :text="okMsg" size="small" />
                    </div>
                </div>
            </div>
        </div>
    </section>
    <div class="language-selector">
        <div class="language__selector">
        <LocaleSwitcher :lang="locale" @changeInput="onChangeInput" :mode="1"/>
        </div>
    </div> 
</template>

<style lang="scss" scoped>
.register-container {
  display: grid;
  grid-template-columns: 50% 50%;
  justify-content: center;
  height: calc(100vh - 60px);
  .register-section--left {
	width: 50dvw;
	height: 100dvh;
	img {
		height: 100%;
		width: 100%;
		object-fit: cover;
	}
  }
  .register-section--right{
	padding-left: 7rem;
	margin-top: 10dvh;
	.register-message_1{
		text-align: left;
		font: normal normal normal 51px/29px 'CircularStd-Book';
		letter-spacing: -2.55px;
		color: #000000;
		margin-top: 60px;
	}
	.register-message_2{
		text-align: left;
		font: normal normal normal 18px/29px 'CircularStd-Book';
		letter-spacing: -0.9px;
		color: #000000;
		opacity: 1;
		margin-top: 28px;
	}
    }
    .error-container, .activate-ok{
        width: 50%;
    }
  @media (min-width: 360px) {
	grid-template-columns: 0% 100%;
	.register-section--left {
		width: 0;
		img {
			width: 0;
			object-fit: cover;
		}
	}	
	.register-section--right{
		padding-left: 4rem;
	}
  }
  @media (min-width: 520px) {
	grid-template-columns: 40% 60%;
	.register-section--left {
		width: 40dvw;
		img {
			width: 40dvw;
			object-fit: cover;
		}
	}	
	.register-section--right{
		padding-left: 4rem;
	}
  }
  @media (min-width: 768px) {
	grid-template-columns: 50% 50%;
	.register-section--left {
		width: 50dvw;
		img {
			width: 50dvw;
			object-fit: cover;
		}
	}
	.register-section--right{
		padding-left: 7rem;
	}
  }
  @media (min-width: 992px) {
  }
  @media (min-width: 1200px) {
  }
  @media (min-width: 1400px) {
  }
}
.language-selector{
	position: absolute;
	bottom: -45px;
	right: 15px;
	width: 175px;
}
.invitation__loader{
    margin-top: 3rem;
}
.activate__btns{
    margin-top: 2rem;
    .yauty-button{
        width: 240px;
    }
}
</style>