<template>
    <input 
        :type="type" 
        :class="classes"
        :placeholder="placeholder" 
        :disabled="disabled"
        v-model.value="input"
    />
</template>

<script>
    import './input.css';
    import { ref, reactive, computed } from 'vue';

    export default {
        name: 'my-input',

        props: {
            type: {
                type: String,
                default: 'text',
            },
            placeholder: {
                type: String,
            },
            value: {
                type: String,
            },
            size: {
                type: String,
                validator: function (value) {
                    return ['small', 'medium', 'large'].indexOf(value) !== -1;
                },
            },
            error: {
                type: Boolean
            },
            color: {
                type: String
            },

            backgroundColor: {
                type: String,
            },
            disabled: {
                type: Boolean,
                default: false
            }
        },

        setup(props, { emit }) {
            const inputVal = ref('');
            return {
                input: inputVal,
                classes: computed(() => ({
                    'storybook-input': true,
                    'storybook-input-error': props.error,
                    [`storybook-input--${props.size}`]: true,
                })),

                style: computed(() => ({
                    backgroundColor: props.backgroundColor,
                    color: props.color ?? 'black',
                    disabled: props.disabled
                })),

                placeholder: computed(() => props.placeholder),

                type: computed(() =>  props.type),
                
                value: computed({
                        get() {
                            return props.value
                        },
                        set(val) {
                            emit('value', val)
                        }
                    }  
                ),
            }
        }
    }
</script>
