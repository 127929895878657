export function wfStatusUnwrapped(id){
  let statusGroup = workfileStatusFilterMappings(id);
  return statusGroup ? statusGroup.workfileStatuses : [];
}
export function workfileStatusFilterMappings(id) {
  const mappings = {
    'workshop':{
      workfileStatuses: [1,2,3,4,5,6,9,10,16],
      filters: [],
      translationKey: 'workfiles.state.primary.1',
    },
    'inProgress':{
      workfileStatuses: [3,4,6],
      filters: [],
      translationKey: 'field.inProgress',
    },
    'pending': {
      workfileStatuses: [5,100],
      filters: [],
      translationKey: 'workfiles.state.5',
    },
    'done': {
      workfileStatuses: [9, 16],
      filters: [],
      translationKey: 'field.done',
    },
    'prepared': {
      workfileStatuses: [10],
      filters: [],
      translationKey: 'field.prepared',
    },
    'quotation': {
      workfileStatuses: [99,100,101,102],
      filters: [],
      translationKey: 'workfiles.state.99',
    },
    'appointment': {
      workfileStatuses: [0,7,8,14],
      filters: [],
      translationKey: 'workfiles.state.0',
    },
    'delivered': {
      workfileStatuses: [11],
      filters: [],
      translationKey: 'workfiles.state.11'
    }
  };
  return id ? (mappings[id] ?? []) : mappings;

};
export function workfileStatusMappings(id){
  const mappings = {
    0:{ 
      name: 'schedule',
      primaryText: 'workfiles.state.primary.0',
      secondaryText: '',
      svgId: 0,
      kpi: [],
      filters:['appointment'] 
    },
    1:{
      name: 'reception',
      primaryText: 'workfiles.state.primary.1',
      secondaryText: '',
      svgId: 3,
      kpi: ['workshop'],
      filters:['receiving'] 
    },
    2:{
      name: 'received',
      primaryText: 'workfiles.state.primary.1',
      secondaryText: '',
      svgId: 3,
      kpi: ['workshop'],
      filters:['receiving'] 
    },
    3:{
      name: 'reparation',
      primaryText: 'workfiles.state.primary.1',
      secondaryText: 'field.inProgress',
      svgId: 3,
      kpi: ['workshop','inProgress'],
      filters:['repairing'] 
    },
    4:{
      name: 'advisorPending',
      primaryText: 'workfiles.state.primary.1',
      secondaryText: 'field.inProgress',
      svgId: 3,
      kpi: ['workshop','inProgress'],
      filters:['repairing'] 
    },
    5:{
      name: 'clientPending',
      primaryText: 'workfiles.state.primary.1',
      secondaryText: 'workfiles.state.secondary.5',
      svgId: 5,
      kpi: ['workshop','inProgress'],
      filters:['repairing','pending'] 
    },
    6:{
      name: 'validated',
      primaryText: 'workfiles.state.primary.1',
      secondaryText: 'field.inProgress',
      svgId: 3,
      kpi: ['workshop','inProgress'],
      filters:['repairing'] 
    },
    7:{
      name: 'appointmentPreparation',
      primaryText: 'workfiles.state.primary.0',
      secondaryText: '',
      svgId: 0,
      kpi: [],
      filters:['appointment'] 
    },
    8:{
      name: 'appointmentPrepared',
      primaryText: 'workfiles.state.primary.0',
      secondaryText: '',
      svgId: 0,
      kpi: [],
      filters:['appointment'] 
    },
    9:{
      name: 'deliveryPreparation',
      primaryText: 'workfiles.state.primary.1',
      secondaryText: 'workfiles.state.secondary.16',
      svgId: 3,
      kpi: [],
      filters:['done'] 
    },
    10:{
      name: 'deliveryPrepared',
      primaryText: 'workfiles.state.primary.1',
      secondaryText: 'workfiles.state.secondary.10',
      svgId: 10,
      kpi: ['workshop','done'],
      filters:['ready'] 
    },
    11:{
      name: 'carDelivered',
      primaryText: 'workfiles.state.workfile.11',
      secondaryText: '',
      svgId: 11,
      kpi: [],
      filters:['delivered'] 
    },
    14:{
      name: 'previousAppointment',
      primaryText: 'workfiles.state.primary.0',
      secondaryText: '',
      svgId: 0,
      kpi: [],
      filters:['appointment'] 
    },
    16:{
      name: 'done',
      primaryText: 'workfiles.state.primary.1',
      secondaryText: 'workfiles.state.secondary.16',
      svgId: 3,
      kpi: ['workshop','inProgress','done'],
      filters:['repairing','done'] 
    },
    99:{
      name: 'quotation',
      primaryText: 'workfiles.state.99',
      secondaryText:'',
      svgId: 99,
      kpi: [],
      filters:['quotation'] 
    },
    100:{
      name: 'quotationPending',
      primaryText: 'workfiles.state.primary.99',
      secondaryText: 'workfiles.state.secondary.100',
      svgId: 5,
      kpi: [],
      filters:['quotation','pending'] 
    },
    101:{
      name: 'quotationDiscarded',
      primaryText: 'workfiles.state.primary.99',
      secondaryText: 'workfiles.state.secondary.101',
      svgId: 99,
      kpi: [],
      filters:['quotation'] 
    },
    102:{
      name: 'quotationClosed',
      primaryText: 'workfiles.state.primary.99',
      secondaryText: 'workfiles.state.secondary.102',
      svgId: 99,
      kpi: [],
      filters:['quotation'] 
    },
    103:{
      name: 'quotationClosed',
      primaryText: 'workfiles.state.primary.99',
      secondaryText: 'Vinculado',
      svgId: 99,
      kpi: [],
      filters:['quotation'] 
    }
  };
  return id != undefined ? mappings[id] : mappings;
}
export function matchStatusToSvg(status,type){
  let svg = undefined;
  if(type == 1){
    svg = '99';
  }else{
    svg = workfileStatusMappings(status).svgId;
  }
  return svg;
}
export function matchNotificationToSvg(notification){
  let svg = undefined;
  if([0,1,9,10].includes(notification)){
    svg = '3';
  }
  else if([4,5,6,13].includes(notification)){
    svg = '99';
  }
  else if([7,8].includes(notification)){
    svg = '0';
  }
  else if([11,12].includes(notification)){
    svg = '10';
  }
  else if(notification == 2){
    svg = '5';
  }
  else if(notification == 3){
    svg = 'piezas';
  }
  return svg;
}
export function getStatusText(status, target, type){
  let statusText = '';
  if(target == 'primary'){
    statusText = workfileStatusMappings(status).primaryText;
  }else if(target == 'secondary'){
    statusText = workfileStatusMappings(status).secondaryText;
  }
  return statusText;
}
export function getNotificationColor(status) {
  let presupuesto = '#cc2c77';
  let cita = '#c5c51a';
  let recepcion = '#262453';
  let reparacion = '#4b49c0';
  let pendiente = '#e29700';
  let listo = '#8db51d';
  let entregado = '#1e9f67';
  let colors = {
    '0': reparacion,
    '1': reparacion,
    '2': pendiente,
    '3': entregado, // invitació ??? this by now ...
    '4': presupuesto,
    '5': presupuesto,
    '6': pendiente,
    '7': cita,
    '8': cita,
    '9': recepcion,
    '10': recepcion,
    '11': listo,
    '12': entregado,
    '13': presupuesto
  };
  return colors[status];
}