import { url } from '@/conf/conf.js';
import { middleware } from "@/services/api/Middleware.js";

export async function getInvitation(data) {
    let options = {
        method: "POST",
        headers: {
            Accept: "application/json",
            'Content-Type': "application/json",
        },
        body: JSON.stringify({
            path: data.path,
            method: "getInvitation",
        }),
    };
    return middleware(url, options, [])()
    .then((response) => response.json())
    .then((data) => data)
    .catch((error) => {
        console.error('Error:', error);
    });
}

export async function acceptInvitation(data) {
    let options =  {
        method: "POST",
        headers: {
            Accept: "application/json",
            'Content-Type': "application/json",
        },
        body: JSON.stringify({
            path: data.path,
            validation_key: data.k,
            method: "acceptInvitation",
        }),
    };
    return middleware(url, options, [])()
    .then((response) => response.json())
    .then((data) => data)
    .catch((error) => {
        console.error('Error:', error);
    });
}

export async function sendInvitation(data) {
    let options = {
        method: "POST",
        headers: {
            Accept: "application/json",
            'Content-Type': "application/json",
        },
        body: JSON.stringify({
            path: data.path,
            validation_key: data.k,
            email: data.email,
            method: "sendInvitation",
        }),
    };
    return middleware(url, options, ['isTokenAlive'])()
    .then((response) => response.json())
    .then((data) => data)
    .catch((error) => {
        console.error('Error:', error);
    });
}