<script setup>
import { ref } from "vue";
import LocalStorage from '@/controller/LocalStorage.js';
import HumanizedDate from '@/components/common/utils/HumanizedDate.vue';
import YLoader from "../common/loaders/YLoader.vue";

const props = defineProps({
    notification: Object,
    loading: Boolean
});
const emit = defineEmits("readOne");
const icons = {
    0: 'fa-solid fa-file-circle-plus',
    1: 'fa-solid fa-file-circle-check',
    2: 'fa-solid fa-file-circle-exclamation',
    3: 'fa-solid fa-plug-circle-plus',
    4: 'fa-solid fa-file-circle-plus',
    5: 'fa-solid fa-file-circle-check',
    6: 'fa-solid fa-file-circle-exclamation',
    7: 'fa-solid fa-file-circle-plus',
    8: 'fa-solid fa-file-circle-check',
    9: 'fa-solid fa-file-circle-plus',
    10: 'fa-solid fa-file-circle-check',
    11: 'fa-solid fa-file-circle-check',
    12: 'fa-solid fa-file-circle-check',
    13: 'fa-solid fa-file-circle-check'
}

const userColor = ref(LocalStorage.getColor());

</script>

<template>
    <div class="notification-card-wrapper" :class="[(props.notification.read == 1 ? 'read' : '')]">
        <div class="notification-image">
            <div class="notification-image-wrapper">
                <div class="notification-image__icon" v-if="props.notification.type == 0 || 
                                                props.notification.type == 1 || 
                                                props.notification.type == 2 || 
                                                props.notification.type == 4 || 
                                                props.notification.type == 5 || 
                                                props.notification.type == 6 ||
                                                props.notification.type == 7 ||
                                                props.notification.type == 8 ||
                                                props.notification.type == 9 ||
                                                props.notification.type == 10 ||
                                                props.notification.type == 11 ||
                                                props.notification.type == 12 ||
                                                props.notification.type == 13">
                    <router-link :to="/workfile/+props.notification.data.workfileId" title="Ver hoja de trabajo">
                        <font-awesome-icon :icon="icons[props.notification.type]" />
                    </router-link>
                </div>
                <div class="notification-image__icon" v-else-if="props.notification.type == 3 ">
                    <router-link :to="/invitation/+props.notification.data.invitationId" title="Ver invitación">
                        <font-awesome-icon :icon="icons[props.notification.type]" />
                    </router-link>
                </div>               
            </div>
        </div>
        <div class="notification-body">
            <div class="notification-title" v-if="props.notification.type == 0 || 
                                                props.notification.type == 1 || 
                                                props.notification.type == 2 || 
                                                props.notification.type == 4 || 
                                                props.notification.type == 5 || 
                                                props.notification.type == 6 ||
                                                props.notification.type == 7 ||
                                                props.notification.type == 8 ||
                                                props.notification.type == 9 ||
                                                props.notification.type == 10 ||
                                                props.notification.type == 11 ||
                                                props.notification.type == 12 ||
                                                props.notification.type == 13">
                <i18n-t :keypath="'notifications'+'.'+props.notification.message" tag="p">
                    <template #or>
                    <span class="notification-data--bold">{{ props.notification.data.orNumber }}</span>
                    </template>
                    <template #brand>
                    <span class="notification-data--bold">{{ props.notification.data.vehicle.brand }}</span>
                    </template>
                    <template #model>
                    <span class="notification-data--bold">{{ props.notification.data.vehicle.model }}</span>
                    </template>
                    <template #plate>
                    <span class="notification-data--bold">{{ props.notification.data.vehicle.plate }}</span>
                    </template>
                    <template #vin>
                    <span class="notification-data--bold">{{ props.notification.data.vehicle.vin }}</span>
                    </template>
                </i18n-t>

            </div>
            <div class="notification-title" v-else-if="props.notification.type == 3">
                {{ $t('notifications.' + props.notification.message) }}
            </div>
            <div class="notification-additional-info">
                <div class="notification-date">
                    <HumanizedDate :timestamp="props.notification.modified"/>
                </div>
            </div>
        </div>
        <div class="notification-actions">
            <div class="notification-actions-wrapper">
                <div class="notification-actions__mark-read" v-if="props.notification.read == 0">
                    <div v-if="!loading" class="notification-actions-wrapper__icon--read" @click="emit('readOne', props.notification.id)" :title="$t('notifications.readOne')">
                        <font-awesome-icon icon="fa-regular fa-circle-dot" />
                    </div>
                    <div class="invitation__content_loader" v-if="loading">
                        <YLoader />
                    </div>                
                </div>
                <div class="notification-actions__mark-read" v-if="props.notification.read == 1"></div>
            </div>
        </div>
    </div>
</template>

<styles lang="scss" scoped>
@import "@/styles/colors.scss";
.notification-card-wrapper{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    gap: 1rem;
}
.notification-image{
    min-width: 35px;
    height: 35px;
    border-radius: 50%;
    flex-grow: 0;
    font-size: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #30A2FF;
    color: white;
    flex-basis: 35px;
}
.notification-data--bold{
    font-weight: 600;
}
.notification-actions-wrapper{
    display: flex;
    flex-direction: row;
    gap: 5px;
    font-size: 16px;
}
.notification-actions-wrapper__icon a {
    padding: 0;
}
.notification-title p{
    margin-bottom: 0;
}
.notification-date{
    .humanized-date{
        font-size: 12px;
        color: $light-grey-descriptions;
    }
}
.notification-actions__mark-read:hover{
    cursor: pointer;
}
.notification-actions__mark-read{
    min-width: 20px;
    .custom-loader{
        margin-top: 8px;
        font-size: 16px;
    }
}
.notification-actions-wrapper__icon--read:hover{
    color: v-bind(userColor);
}
.notification-actions-wrapper__icon--read{
    margin-right: 30px;
}
.notification-image__icon a{
    color: #30A2FF;
    &:hover{
        color: white;
    }
}
.notification-image{
    background-color:white;
}
.notification-image:hover{
    .notification-image__icon a{
        color: white
    }
    background-color: #30A2FF;
}
.notification-actions-wrapper__icon--read:hover{
    color: red;
    font-size: 20px;
}
.read{
    //background-color: #f4f4f5;
    border-radius: 15px;
}
</styles>