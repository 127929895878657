<script>
export default class FormController {
  static validEmail(email) {
    const re =      /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(email);
  }
  static validPassword(password) {
    return  this.minRule(password, 8) && 
            this.uppperRule(password) && 
            this.lowerRule(password) && 
            this.numberRule(password) && 
            this.specialRule(password);
  }
  static minRule(value, min) {
    return value.length >= min;
  }
  static maxRule(value, max) {
    return value.length <= max;
  }
  static uppperRule(value) {
    return /[A-Z]/.test(value);
  }
  static lowerRule(value) {
    return /[a-z]/.test(value);
  }
  static numberRule(value) {
    return /[0-9]/.test(value);
  }
  static specialRule(value) {
    return /[!@#$%^&*(),.?":{}|<>]/.test(value);
  }
  static notEmpty(string) {
    return string.length == 0;
  }
  static validarNifCif(value) {
    var valid = false;
    var regexCIF = /^([ABCDEFGHJKLMNPQRSUVW])(\d{7})([-]?)([0-9A-J])$/;
    var regexDNI = /^(([0-9]{8})([-]?)([A-Z]))$/;
    var regexNIE = /^(([X-Z])|([LM])){1}([-]?)((\d){7,8})([-]?)([A-Z]{1})$/;
    var str = value.toString().toUpperCase();
    if(regexCIF.test(str)) {
      valid = this.checkNIFSpain(str)
    }else if(regexDNI.test(str)) {
      valid = this.checkDNISpain(str)
    }else if(regexNIE.test(str)) {
      valid = this.checkNIE(str)
    }
     return valid;     
  }

  static checkNIFSpain(value) {
    var dni = value;
    dni = dni.toUpperCase();
    var table = ['J', 'A', 'B', 'C', 'D', 'E', 'F', 'G', 'H', 'I'];
    if(dni.length==9) {
      var evenSum = 0; //even
      var oddSum = 0; //odd
      evenSum = parseInt(dni.substring(2, 3)) + parseInt(dni.substring(4, 5)) + parseInt(dni.substring(6, 7));
      var i = 1;
      while (i <= 8) {
        let p = 2 * parseInt(dni.substring(i, i + 1));
        oddSum += (function() {
          if (p > 9) {
            p = p.toString();
            return parseInt(parseInt(p.charAt(0))+parseInt(p.charAt(1)));
          } else {
            return parseInt(p);
          }
        })();
        i+=2;
      }
      var totalSum = evenSum + oddSum;
      var digitControl = totalSum % 10;
      digitControl = 10 - digitControl;
      if (digitControl == 10) {
        digitControl = 0;
      }
      if((table[digitControl] == dni[8]) || (parseInt(dni[8]) == digitControl)) {
        return true;
      } 
    }
    return false;
  }
  static checkDNISpain(value) {
    var dni = value;
    dni = dni.toUpperCase();
    var validatorES = { 0:'T', 1:'R', 2:'W', 3:'A', 4:'G', 5:'M', 6:'Y', 7:'F', 8:'P', 9:'D', 10:'X', 11:'B', 12:'N', 13:'J', 14:'Z', 15:'S', 16:'Q', 17:'V', 18:'H', 19:'L', 20:'C', 21:'K', 22:'E' };
    if ((dni.length==9) || (dni.length==10)) {
        var dniLetter = dni.slice(dni.length - 1);
        var dniNumber = dni.slice(0, -1);
        var mod = (parseInt(dniNumber) % 23);
        if(validatorES[mod] == dniLetter) {
          return true;
        } 
    }
    return false;
  }
  static checkNIE(value) {
    var dni = value;
    dni = dni.toUpperCase();
    var validatorES = { 0:'T', 1:'R', 2:'W', 3:'A', 4:'G', 5:'M', 6:'Y', 7:'F', 8:'P', 9:'D', 10:'X', 11:'B', 12:'N', 13:'J', 14:'Z', 15:'S', 16:'Q', 17:'V', 18:'H', 19:'L', 20:'C', 21:'K', 22:'E' };
    if ((dni.length==9) || (dni.length==10)) {
      if((dni[0] == 'X') || (dni[0] == 'Y') || (dni[0] == 'Z')) {
        if(dni[0] == 'X') {
          dni = dni.replace('X',0);
        } else if(dni[0] == 'Y') {
          dni = dni.replace('Y',1);
        } else {
          dni = dni.replace('Z',2);
        }
        var dniLetter = dni.slice(dni.length - 1);
        var dniNumber = dni.slice(0, -1);
        var mod = (parseInt(dniNumber) % 23);
        if(validatorES[mod] == dniLetter) {
          return true;
        } 
      } 
    }
    return false;
  }
}
</script>
