<script setup>
import { ref } from "vue";
import { useRouter } from 'vue-router';
import WorkfileBody from '@/components/workfile/body/WorkfileBody.vue';
import LocalStorage from '@/controller/LocalStorage.js';
import { useWorkfilesStore } from '@/stores/workfiles.js';
import { useI18n } from "vue-i18n";
const props = defineProps({
  fastFilter: String
});

const userColor = ref(LocalStorage.getColor());
const { t } = useI18n();
const router = useRouter();
const workfilesStore = useWorkfilesStore();
const workfile = ref({});
const path =  router.currentRoute.value.params.id;
const userKey = ref(LocalStorage.getKey());
const loading = ref(false);
function getWorkfile() {
  loading.value = true;
  workfilesStore
    .fetchWorkfile({ k: userKey.value, id: path })
    .then((res) => {
      if (res) {
        workfile.value = res;
        loading.value = false;
      } else if (res.errors && res.errors[0].error == 'INVALID_TOKEN') {
        router.push('/logout');
      }
      return 0;
    })
    .catch((err) => {
      console.log(err);
    });
}
getWorkfile();
</script>

<template>
    <div class="workfile-detail">
        <WorkfileBody :workfile="workfile"/>
    </div>
</template>

<style lang="scss">
.workfile-detail{
  background-color: #EFF3F3;
  padding: 1rem 3rem 3rem 3rem;
}
</style>