import * as Comlink from 'comlink';
import NotificationWorker from '@/workers/notificationsWorker?worker';
import SessionWorker from '@/workers/sessionWorker?worker';

// const notificationWorkerInstance = new NotificationWorker();
// const sessionWorkerInstance = new SessionWorker();
// export const Notifications = Comlink.wrap(notificationWorkerInstance);
// export const Sessions = Comlink.wrap(sessionWorkerInstance);

// export const notificationsPollStart = async (params) => { 
//     await Notifications.notificationsPollStart(params);
// };

// export const setUpdateFunc = async (worker, func) => {
//     await worker.setUpdateFunc(Comlink.proxy(func));
// };

// export const sessionPollStart = async (params) => {
//     await Sessions.sessionPollStart(params);
// };

// export const stopServiceWorkers = async () => {
//     await Sessions.stopPolling();
//     await Notifications.stopPolling();
// };
let notificationWorkerInstance = null;
let sessionWorkerInstance = null;

export const Notifications = {
  instance: null,
  start: function() {
    return new Promise((resolve) => {
        if (!notificationWorkerInstance) {
            notificationWorkerInstance = new NotificationWorker();
            this.instance = Comlink.wrap(notificationWorkerInstance);
            resolve();
        }
    });
  },
  stop: async function() {
    if (notificationWorkerInstance) {
      await this.instance.stopPolling();
      notificationWorkerInstance.terminate();
      notificationWorkerInstance = null;
      this.instance = null;
    }
  }
};

export const Sessions = {
  instance: null,
  start: function() {
    return new Promise((resolve) => {
        if (!sessionWorkerInstance) {
            sessionWorkerInstance = new SessionWorker();
            this.instance = Comlink.wrap(sessionWorkerInstance);
            resolve();
        }
    });
  },
  stop: async function() {
    if (sessionWorkerInstance) {
      await this.instance.stopPolling();
        sessionWorkerInstance.terminate();
        sessionWorkerInstance = null;
        this.instance = null;
    }
  }
};

export const notificationsPollStart = async (params) => { 
    await Notifications.start();
    await Notifications.instance.notificationsPollStart(params);
};

export const setUpdateFunc = async (worker, func) => {
    await worker.instance.setUpdateFunc(Comlink.proxy(func));
};

export const sessionPollStart = async (params) => {
    await Sessions.start();
    await Sessions.instance.sessionPollStart(params);
};

export const stopServiceWorkers = async () => {
    await Sessions.stop();
    await Notifications.stop();
};