<script setup>
import { computed, onMounted, ref } from "vue";
import WorkfileStatusItem from '@/components/workfile/body/components/WorkfileStatusItem.vue';
const props = defineProps({
  log: Object,
  documents: Object,
  type: [String, Number]
});
const statusList = computed(() => {
  props.documents.forEach(doc => {
    let element;
    if(![0, 7, 8, 14].includes(doc.status)){  // decidim no mostrar cap pdf de cita ja que aquests son per enviar a magatzem i no es mostren a client
      element = props.log.find(item => item.status === doc.status);
    }
    if(element){
      element.link = doc.link;
    }
  });
  return props.log
})
</script>

<template>
    <div class="workfile-status-line">
        <div v-for="(status, index) in statusList" :class="{ 'last': index === props.log.length - 1 }">
          <WorkfileStatusItem :status="status" :type="props.type"/>
        </div>
    </div>
</template>

<style lang="scss">
.workfile-status-line{
  display: flex;
  flex-direction: column;
  gap: 1rem;
  margin-left: 6rem;
  .last .dash-wrapper {
    display: none;
  }
  @media only screen and (max-width: 720px) {
    margin-left: 2rem;
  }
  @media only screen and (min-width: 721px) {
    margin-left: 2.5rem;
  }
  @media only screen and (min-width: 1279px) {
    margin-left: 6rem;
  }
  @media only screen and (min-width: 1680px) {
    margin-left: 6rem;
  }
}
</style>