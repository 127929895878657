import { url } from '@/conf/conf.js';
import { middleware } from "@/services/api/Middleware.js";

export async function notificationsRead(params) {
    let options =  {
        method: "POST",
        headers: {
            Accept: "application/json",
            'Content-Type': "application/json",
        },
        body: JSON.stringify({
            validation_key: params.k,
            notifications: params.notifications,
            method: "notificationsRead",
        }),
    };
    return middleware(url, options, ['isTokenAlive'])()
    .then((response) => response.json())
    .then((data) => { data })
    .catch((error) => {
        console.error('Error:', error);
    });       
}

export async function notificationsReadAll(params) {
    let options =  {
        method: "POST",
        headers: {
            Accept: "application/json",
            'Content-Type': "application/json",
        },
        body: JSON.stringify({
            validation_key: params.k,
            notifications: params.notifications,
            method: "notificationsRead",
        }),
    };
    return middleware(url, options, ['isTokenAlive'])()
    .then((response) => response.json())
    .then((data) => { data })
    .catch((error) => {
        console.error('Error:', error);
    });       
}