<template>
  <div :class="classes" :style="style">
    {{ text }}
  </div>
</template>

<script>
import "./title.css";
import { reactive, computed } from 'vue';

export default {
  name: 'yauty-title',

  props: {
    primary: {
      type: Boolean,
      default: false,
    },
    text: {
      type: String,
      required: true,
    },
    size: {
      type: String,
      validator(value) {
        return ["ssmall", "small", "medium", "large"].indexOf(value) !== -1;
      },
    },
    color: {
      type: String,
    },
  },

  setup(props, { emit }) {
    props = reactive(props);
    return {
      classes: computed(() => ({
        "storybook-title": false,
        'storybook-title--primary': props.primary,
        [`storybook-title--${props.size || 'medium'}`]: true,
      })),
      style: computed(() => ({
        color: props.color ? props.color : 'black',
      })),
    };
  },
};
</script>
