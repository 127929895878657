import { defineStore, acceptHMRUpdate } from 'pinia';
import { useNotificationStore } from '@/stores/notification.js';
import { useCommunicatorStore } from '@/stores/communicator.js';
import { notificationsPollStart, sessionPollStart, stopServiceWorkers, setUpdateFunc, Notifications, Sessions } from '@/workers/workerApi';
import LocalStorage from '@/controller/LocalStorage.js';
import { ref } from "vue";

export const useWorkersStore = defineStore('workers', {
    id: "workers",
	state: () => ({ 
        areWorkersRunning: false,
    }),
    getters: { 
    },
    actions: {
        async startServiceWorkers() {
            const notificationStore = useNotificationStore();
            const communicatorStore = useCommunicatorStore();
            const userKey = ref(LocalStorage.getKey());
            const sessionExpiry = ref(LocalStorage.getSessionExpiration());
            if(userKey.value) {
                await notificationsPollStart({k: userKey.value});
                await sessionPollStart({ sessionExpiry: sessionExpiry.value });                
                setUpdateFunc(Notifications, (data) => {
                    notificationStore.updateNotificationsData(data);
                });
                setUpdateFunc(Sessions, async (data) => {
                    if (data.stopWorkers) {
                        await stopServiceWorkers().then(() => {
                            communicatorStore.toggleSessionExpiration();
                        });
                    }
                });
                this.areWorkersRunning = true;
            }
        },
        async stopServiceWorkers() {
            this.areWorkersRunning = false; 
            return await stopServiceWorkers();
        }
    }
});

if (import.meta.hot) {
    import.meta.hot.accept(acceptHMRUpdate(useWorkersStore, import.meta.hot))
}