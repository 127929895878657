export function yautyDefaultBranding(){
    return {
        PRIMARY_COLOR:       "#4B49C0",
        SECONDARY_COLOR:     "#07ccb9",
        TERTIARY_COLOR:      "#262453",
        LOGO:                "/assets/logos/yauty_vertical.svg",
        SECONDARY_LOGO:      "https://yauty.com/public/assets/login/login_logo.png",
        LOGIN_LOGO:          "https://yauty.com/public/assets/login/login_logo.png",
        DEFAULT_LOGIN_LOGO:  "https://yauty.com/public/assets/login/login_logo.png",
        LOGIN_IMAGE:         "https://yauty.com/public/assets/login/login.png",
        DEFAULT_LOGIN_IMAGE: "https://yauty.com/public/assets/login/login.png",
    }
}

export function northgateDefaultBranding(){
    return {
        PRIMARY_COLOR: "#00b140",
        SECONDARY_COLOR: "#06FF28",
        TERTIARY_COLOR: "#023614"
    }
}

export function ivecoDefaultBranding(){
    return {
        PRIMARY_COLOR: "#184CDB",
        SECONDARY_COLOR: "#1A9CFF",
        TERTIARY_COLOR: "#050E27",
        LOGIN_IMAGE: '/assets/login/iveco_img.png',
        LOGIN_LOGO: 'https://ws1.cloudactivereception.com/wsaux/Service.svc/GetImage?action=brand&id=35&width=150&validation_key=sqpOzl4mUOcDSq7NlMilzqMBOlvYICQBAM0osTIowX0=',
        LOGO: '/assets/logos/iveco_vertical.svg'
    }
}

export function hexToHSL(hex, raw){
    // Convert hex to RGB first
    let r = 0, g = 0, b = 0;
    if (hex && hex.length == 4) {
        r = "0x" + hex[1] + hex[1];
        g = "0x" + hex[2] + hex[2];
        b = "0x" + hex[3] + hex[3];
    } else if (hex && hex.length == 7) {
        r = "0x" + hex[1] + hex[2];
        g = "0x" + hex[3] + hex[4];
        b = "0x" + hex[5] + hex[6];
    }
    // Then to HSL
    r /= 255;
    g /= 255;
    b /= 255;
    let cmin = Math.min(r,g,b),
        cmax = Math.max(r,g,b),
        delta = cmax - cmin,
        h = 0,
        s = 0,
        l = 0;

    if (delta == 0)
        h = 0;
    else if (cmax == r)
        h = ((g - b) / delta) % 6;
    else if (cmax == g)
        h = (b - r) / delta + 2;
    else
        h = (r - g) / delta + 4;

    h = Math.round(h * 60);

    if (h < 0)
        h += 360;

    l = (cmax + cmin) / 2;
    s = delta == 0 ? 0 : delta / (1 - Math.abs(2 * l - 1));
    s = +(s * 100).toFixed(1);
    l = +(l * 100).toFixed(1);
    if(raw){
        return {h:h, s:s, l:l};
    }else{
        return "hsl(" + h + "," + s + "%," + l + "%)";
    }
}

export function hslAdjust(color, saturation, lightness){

        let hslColor = hexToHSL(color, true);
        let [h, s, l] = [hslColor.h, hslColor.s, hslColor.l];

        // Adjust the saturation and lightness
        s = Math.min(Math.max(s + saturation, 0), 100);
        l = Math.min(Math.max(l + lightness, 0), 100);

        return `hsl(${h}, ${s}%, ${l}%)`;
}
