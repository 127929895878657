import { createI18n } from 'vue-i18n';
import messages from '@intlify/vite-plugin-vue-i18n/messages';

let locale = window.location.pathname.replace(/^\/([^\/]+).*/i, "$1");
const defaultLocale = "es";
const availableLocales = ['ca', 'es', 'gb', 'us', 'fr', 'pt'];

locale = locale.replace('yauty', '');
locale = locale.trim().length && locale != '/' ? locale : defaultLocale;

if (localStorage.getItem('yautyLanguage')) {
  let lang = localStorage.getItem('yautyLanguage');
  locale = lang ?? 'es';
}
export const i18n = createI18n({
  legacy: false,
  globalInjection: true,
  locale,
  fallbackLocale: 'es',
  availableLocales,
  messages,
});
