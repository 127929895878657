<script>
import RecoverPasswordForm from '@/components/recoverPassword/RecoverPasswordForm.vue';

export default {
  data() {
    return {
      imgYautyPath: "../public/assets/login/login_logo.png",
      imgLogginPath: '../public/assets/login/login.png',
    };
  },
  components: {
    RecoverPasswordForm,
  },
};
</script>

<template>
  <section class="recover-password__container">
    <RecoverPasswordForm />
  </section>
</template>

<style lang="scss" scoped>
</style>
