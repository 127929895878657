<script>
import RecoverAccountForm from '@/components/recoverPassword/RecoverAccountForm.vue';

export default {
  data() {
    return {
      imgYautyPath: "../public/assets/login/login_logo.png",
      imgLogginPath: '../public/assets/login/login.png',
    };
  },
  components: {
    RecoverAccountForm
  },
};
</script>

<template>
  <section class="recover-password__container">
    <RecoverAccountForm />
  </section>
</template>

<style lang="scss" scoped>
</style>
