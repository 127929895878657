<script setup>
import { ref } from "vue";
import { useI18n } from 'vue-i18n';
import Title from "@/stories/Labels/Titles/Title.vue";
import CustomTable from '@/components/common/utils/CustomTable.vue';

const { t } = useI18n();
const i18n = useI18n();
const defaultLocale = ref(i18n.locale);

const headers = [
    { text: t('vehicle.license_plate') + '/' + t('vehicle.vin'), value: 'plate' },
    { text: t('vehicle.brand') + '/' + t('vehicle.model'), value: 'brand' },
    { text: t("vehicle.itv_date"), value: "inspection_date" },
];

const listPage = ref(1);
const props = defineProps({
    vehicles: Array
});

const vehiclesFormat = ref([]);
props.vehicles.map((item) => {
    let v = {};
    let plate = item.plate
    if(item.vin) {
        if(plate && plate != '') {
            plate += ' / ' + item.vin;
        } else {
            plate = item.vin
        }
    }
    let brand = item.brand
    if(item.model) {
        if(brand && brand != '') {
            brand += ' / ' + item.model;
        } else {
            brand = item.model
        }
    }
    let inspection_date = '';
    if(item.inspection_date) {
        const date = new Date(item.inspection_date);
        const localeLang = ref(defaultLocale.value == 'es' ? 'es-ES' : 'en-GB');
        let esFormatter = new Intl.DateTimeFormat(localeLang.value, {
            year: 'numeric',
            month: '2-digit',
            day: '2-digit',
        });
        inspection_date = esFormatter.format(date);
    }
    v = {
        plate: plate,
        brand: brand,
        inspection_date: inspection_date
    }
    vehiclesFormat.value.push(v);
});

function loadPage(index) {

}
</script>

<template>
    <div class="invitation__client_vehicle pt-3">
        <div class="invitation__title">
            <Title :text="$t('vehicle.vehicle_info')" size="ssmall"> </Title>
        </div>
        <div class="invitation_data_vehicles_wrapper">
            <CustomTable 
                :headers="headers" 
                :items="vehiclesFormat" 
                :total="vehicles.length"
                :page="listPage"
                @callLoadPage="loadPage"
                />
        </div>
    </div>
</template>

<style lang="scss">
.invitation_data_vehicles_wrapper {

} 
</style>