<script setup>
import { computed, ref, onMounted, onUnmounted  } from "vue";
import { useRouter } from "vue-router";
import { storeToRefs } from "pinia";
import YBtn from "../form/YBtn/YBtn.vue";
import { useSidenavbarStore } from "@/stores/sidenavbar.js";
import { useI18n } from "vue-i18n";
import { useNotificationStore } from '@/stores/notification.js';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import NotificationsModal from '@/components/common/modals/NotificationsModal.vue';
import LocalStorage from '@/controller/LocalStorage.js';
import YLoader from "../loaders/YLoader.vue";
const bell = '/assets/layout/campana.svg';

const { t } = useI18n();

const router = useRouter();
const homeIcon = '/assets/home/home.svg';
const orIcon = '/assets/home/reparacion.svg';
const carIcon = '/assets/home/car.svg';
const configIcon = '/assets/home/ajustes.svg';

const sidenavbarStore = useSidenavbarStore();
const isMobileView = ref(()=> window.innerWidth <= 576);
const isDesktopView = ref(()=> window.innerWidth > 576)
const updateView = () => {
  isMobileView.value = window.innerWidth <= 576;
  isDesktopView.value = window.innerWidth > 576;
};
onMounted(() => {
  window.addEventListener('resize', updateView);
});
onUnmounted(() => {
  window.removeEventListener('resize', updateView);
});

const isSidenavbarCollapsed = computed(()=>sidenavbarStore.isCollapse);

const notificationStore = useNotificationStore();
const notifications = storeToRefs(notificationStore);
const { getNotificationsCount: notificationsCount } = storeToRefs(notifications);
const loadingBell = ref(false);
let yautyData = ref("");

let userNick = ref("");
const userColor = ref(LocalStorage.getColor());
const userSecondaryColor = ref(LocalStorage.getColorSecondary());
const userTertiaryColor = ref(LocalStorage.getColorTertiary());
const selectedColor = ref(LocalStorage.getColorSelected());
const hoverSelectedColor = ref(LocalStorage.getColorSelectedHover());
if (localStorage.getItem('yautyData')) {
  yautyData = ref(JSON.parse(localStorage.getItem('yautyData')));
  userNick = yautyData.value.name.charAt(0).toUpperCase();
}

const props = defineProps({
    showNotifications: ref(Boolean)
})

const emit = defineEmits(['notificationsShown','notificationsHidden']);

function toggleNotificationsModal(event){
  event.stopPropagation();
  if(!props.showNotifications){
    emit('notificationsShown');
  }else{
    emit('notificationsHidden');
  }
  props.showNotifications = !props.showNotifications;
}
function handleClick(event){
  if(props.showNotifications && event.target.id == 'navbarText'){
    toggleNotificationsModal(event);

  }
}

const yautyLogo = '/assets/logos/yauty-logo.svg';

const selectedIndex = ref(null);
const selectItem = (index,event) => {
  selectedIndex.value = index;
  event.stopPropagation();
}

//for the responsive menu
const isMenuOpen = ref(false);
const toggleMenu = () => {
  isMenuOpen.value = !isMenuOpen.value;
};

</script>

<template>
  <nav
    v-if="!$route.meta.hideNavbar"
    class="navbar"
    :class="[sidenavbarStore.isCollapse ? '' : 'extended-wrapper']"
    @click="handleClick"
  >
      <div class="navbar__brand yauty-logo">
        <router-link
          to="/"
          class="nav-link"
          title=""
          data-bs-toggle="tooltip"
          data-bs-placement="right"
          data-bs-original-title="Home"
        >
          <span>
            <img :src="yautyLogo" style="width:20dvw;height:auto"/>
          </span>
        </router-link>
      </div>
      <div class="navbar__toggle" @click="toggleMenu">
          <font-awesome-icon class="icon" v-if="!isMenuOpen" icon="bars" />
          <font-awesome-icon class="icon" v-else icon="times" />
      </div>
      <ul v-if="isMobileView" :class="{'navbar__menu': true, 'navbar__menu--open': isMenuOpen}">
            
            <li  class="menu-item-wrapper navbar__item"  :class="{'selected' : selectedIndex === 0}">
              <router-link
              to="/"
              class="route-linker"
              :class="router.currentRoute.value.name === 'home' ? 'active' : ''"
            >
            <div class="sidenav-item">
              <div class="sidenav-link" @click="event => selectItem(0, event)">
                <div class="item-icon">
                  <img :src="homeIcon" style="width:6dvw;"/>
                </div>
                <div class="menu-label">{{ $t('route.home') }}</div>
              </div>
            </div>
              </router-link>
            </li>
            <li class="menu-item-wrapper navbar__item" :class="{'selected' : selectedIndex === 1}">
              <router-link
              to="/vehicles"
              class="route-linker"
              :class="
                router.currentRoute.value.name === 'vehicles' ||
                router.currentRoute.value.name === 'vehicle' ||
                router.currentRoute.value.name === 'vehicle/add' ||
                router.currentRoute.value.name === 'createAppointment'
                  ? 'active'
                  : ''
              "
            >
            <div class="sidenav-item">
              <div class="sidenav-link" @click="event => selectItem(1, event)">
                <div class="item-icon">
                  <img :src="carIcon" style="width:6dvw;"/>
                </div>
                <div class="menu-label">{{ $t('route.vehicles') }}</div>
              </div>
            </div>
            </router-link>
            </li>
            <li  class="menu-item-wrapper navbar__item" :class="{'selected' : selectedIndex === 2}">
              <router-link
              to="/workfiles"
              class="route-linker"
              :class="
                router.currentRoute.value.name === 'workfiles' ? 'active' : '' ||
                router.currentRoute.value.name === 'workfile' ? 'active' : ''
              "
            >
            <div class="sidenav-item">
              <div class="sidenav-link" @click="event => selectItem(2, event)">
                <div class="item-icon">
                  <img :src="orIcon" style="width:6dvw;"/>
                </div>
                <div class="menu-label">{{ $t('route.workfiles') }}</div>
              </div>
            </div>
            </router-link>
            </li>
            <li  class="menu-item-wrapper navbar__item" :class="{'selected' : selectedIndex === 3}">
              <router-link
              to="/configuration"
              class="route-linker"
              :class="
                router.currentRoute.value.name === 'configurationList' ||
                router.currentRoute.value.name === 'configuration' ||
                router.currentRoute.value.name === 'createInvitation' ? 'active' : ''
              "
            >
            <div class="sidenav-item">
              <div class="sidenav-link" @click="event => selectItem(3, event)">
                <div class="item-icon">
                  <img :src="configIcon" style="width:6dvw;"/>
                </div>
                <div class="menu-label">{{ $t('route.configuration') }}</div>
              </div>
            </div>
            </router-link>
            </li>

            <li class="navbar__item  notifications-item" >
              <div v-if="!loadingBell" class="navbar-bell" @click="toggleNotificationsModal" :title="$t('field.notifications')">
                <img :src="bell" alt="" class="bell">
                <div class="navbar__notifications" v-if="notificationsCount > 0 && !loadingBell" @click="toggleNotificationsModal">
                <span>{{ notificationsCount }}</span>
              </div>
              </div>
              
              <div class="notifications-label">
                <span>{{ $t('field.notifications') }}</span>
              </div>
              <div class="notifications_loader" v-if="loadingBell">
                  <YLoader />
              </div> 
            </li>

          </ul>
          <ul v-if="isDesktopView " class="menu">
            <li class="navbar__item d-flex notifications-item">
              <div v-if="!loadingBell" class="navbar-bell" @click="toggleNotificationsModal" :title="$t('field.notifications')">
                <img :src="bell" alt="" class="bell">
              </div>
              <div class="navbar__notifications" v-if="notificationsCount > 0 && !loadingBell" @click="toggleNotificationsModal">
                <span>{{ notificationsCount }}</span>
              </div>
              <div class="notifications-label">
                <span>{{ $t('field.notifications') }}</span>
              </div>
              <div class="notifications_loader" v-if="loadingBell">
                  <YLoader />
              </div> 
            </li>
            <li class="nav-item dropdown">
              <YBtn
                class="d-flex nav-link"
                id="navbarDropdown"
                role="button"
                data-bs-toggle="dropdown"
                aria-haspopup="true"
                aria-expanded="false"
                :rounded="true"
                roundedSize="full"
                :noPadding="true"
              >
                <div class="branding__link">{{ userNick }}</div>
              </YBtn>
              <div class="dropdown-menu" aria-labelledby="navbarDropdown">
                <router-link to="/user" class="dropdown-item">{{
                  $t("user.profile")
                }}</router-link>
                <div class="dropdown-divider"></div>
                <router-link to="/logout" class="dropdown-item">{{
                  $t("logout.logout")
                }}</router-link>
              </div>
            </li>
      </ul>
  </nav>
  <NotificationsModal :show="props.showNotifications" @loading="loadingBell = !loadingBell"/>
</template>

<style lang="scss" scoped>

.navbar {
  * {
    font-family: 'CircularStd-Book';
  }
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: #515151;
  border-bottom: 1px solid #f5f5f5;
  height: 8dvh;
  position: relative;
  
  &__toggle{
    display: none;
    font-size: 1.5rem;
    cursor: pointer;
    z-index: 6;
  }

  &__menu{
    overflow: hidden;
    display: none;
    list-style: none;
    display: flex;
    margin: 0;
    padding: 0;
    @media (max-width: 576px) {
      max-height: 0;
      overflow: hidden;
      flex-direction: column;
      align-items: flex-start;
      width: 100%;
      transition: max-height 0.3s ease-in-out;
      background-image: linear-gradient(to right, v-bind(userTertiaryColor), v-bind(userColor)) !important;
      margin-top: 7.3dvh;
      position: absolute;
      right: 0;
      border-radius: 0px 0px 12px 12px;
      &--open{
        max-height: 500px;
        margin-top: 7.3dvh;
        border-radius: 0px 0px 12px 12px;
      }
    }
  }

  &__item{
    margin-left: 1rem;
  }

  a {
    display: block;
    width: 100%;
    color: #515151;
    cursor: pointer;
    text-decoration: none;
    font-weight: 600;
    &:nth-child(3) {
      border-bottom-left-radius: calc(0.375rem - 1px);
      border-bottom-right-radius: calc(0.375rem - 1px);
    }
    &:nth-child(1) {
      border-top-left-radius: calc(0.375rem - 1px);
      border-top-right-radius: calc(0.375rem - 1px);
    }
    &:hover {
      color: #0195d8;
    }
    img {
      width: 100%;
      height: 100%;
    }
  }
  .yauty-logo{
    padding-left: 8dvw;
    margin-top: 1.8dvh;
    visibility: hidden;
  }

  .dropdown-menu[data-bs-popper] {
    left: -5.7rem;
    top: 5dvh;
  }
  .dropdown-menu {
    --bs-dropdown-zindex: 1000;
  --bs-dropdown-min-width: 10rem;
  --bs-dropdown-padding-x: 0;
  --bs-dropdown-padding-y: 0rem;
  --bs-dropdown-spacer: 0.125rem;
  --bs-dropdown-font-size: 1rem;
  --bs-dropdown-color: #212529;
  --bs-dropdown-bg: #fff;
  --bs-dropdown-border-color: var(--bs-border-color-translucent);
  --bs-dropdown-border-radius: 0.375rem;
  --bs-dropdown-border-width: 1px;
  --bs-dropdown-inner-border-radius: calc(0.375rem - 1px);
  --bs-dropdown-divider-bg: var(--bs-border-color-translucent);
  --bs-dropdown-divider-margin-y: 0rem;
  --bs-dropdown-box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15);
  --bs-dropdown-link-color: #212529;
  --bs-dropdown-link-hover-color: #1e2125;
  --bs-dropdown-link-hover-bg: #e9ecef;
  --bs-dropdown-link-active-color: #fff;
  --bs-dropdown-link-active-bg: #0d6efd;
  --bs-dropdown-link-disabled-color: #adb5bd;
    --bs-dropdown-item-padding-x: 0.9rem;
    --bs-dropdown-item-padding-y: 0.9rem;
    --bs-dropdown-header-color: #6c757d;
    --bs-dropdown-header-padding-x: 1rem;
    --bs-dropdown-header-padding-y: 1rem;
    position: absolute;
    z-index: var(--bs-dropdown-zindex);
    min-width: var(--bs-dropdown-min-width);
    padding: var(--bs-dropdown-padding-y) var(--bs-dropdown-padding-x);
    margin: 0;
    font-size: var(--bs-dropdown-font-size);
    color: var(--bs-dropdown-color);
    text-align: left;
    list-style: none;
    background-color: var(--bs-dropdown-bg);
    background-clip: padding-box;
    border: var(--bs-dropdown-border-width) solid var(--bs-dropdown-border-color);
    border-radius: var(--bs-dropdown-border-radius);
  }
  .branding__link {
    color: white;
    background-color: v-bind(userColor);
    height: 32px;
    width: 32px;
    text-align: center;
    border-radius: 30px;
    font-weight: bold;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .navbar-bell {
    position: relative;
    color: v-bind(userColor);
    font-size: 23px;
    cursor: pointer;
    margin-top: 10px;
    @media (max-width: 576px) {
      margin-left: 28px;
      margin-top: 25px;
      padding-bottom: 15px;
    }
    .bell{
      height:3.9dvh;
      @media (max-width: 576px) {
        height: 3.2dvh;
        filter: invert(100%);
      }
    }
  }
  .navbar__notifications {
    height: 2.5dvh;
    width: 2.5dvh;
    border-radius: 50%;
    background-color: v-bind(userColor);
    color: white;
    text-align: center;
    font-size: 11px;
    left: -13px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 5px;
    cursor: pointer;
    @media (max-width: 576px) {
      height: 1.8dvh;
      width: 1.8dvh;
      font-size: 9px;
      top: -45px;
      left: 15px;
    }
  }
  .notifications_loader{
    display: flex;
    position: relative;
    align-items: center;
    .custom-loader{
      font-size: 16px;
    }
  }
  .notifications-label{
    margin-left: 0.5rem;
    color: #CBD5E1;
    align-self: center;
    margin-top: 0.5rem;
    @media (max-width: 576px) {
      margin-top: 0px;
      margin-left: 25px;
      color: white;
    }
  }
  .notifications-item{
    display: flex;
    @media (max-width:576px) {
      flex-direction: row;
    }
  }
  
  }

  .route-linker {
    &.active {
      .item-icon {
      border-left: 3px solid v-bind(userSecondaryColor);
      padding-left: 10px;
      margin-left: 20px;
      }
      .sidenav-link{
        margin-left: 0px;
        background-color: v-bind(selectedColor);
      }
    }
  }

  .sidenav-link{
    display: flex;
    width: 100dvw;
    gap: 22px;
    margin-left:30px;
    padding-left: 10px;
    padding-bottom: 20px;
    padding-top: 20px;
    position: relative;
    color: white;
    font-size: 16px;
    cursor: pointer;
  }

  .sidenav-item{
    border-bottom: 0.1px solid rgba(194, 194, 194, 0.3);
    margin-left: -15px;
  }
  
  .selected .sidenav-item{
    background-color: v-bind(selectedColor)
  }

  
  .menu-label{
    padding: 5px 0px;
  }

  .menu{
    position: absolute;
    top: 1dvh;
    right:0;
    display: flex;
    flex: 3;
    gap: 20px;
    align-items: center;
    list-style: none;
    margin: 0;
    padding: 0;
    padding-right: 1.2dvw;
    @media (max-width:576px) {
      display: none;
    }
    .nav-item{
      padding: 0 10px;
    }
  }
  //moviles
  @media (max-width:576px){
    .navbar{
      border-bottom: none;
      flex-direction: column;
      align-items: flex-start;
      background-image: linear-gradient(to right, v-bind(userTertiaryColor), v-bind(userColor)) !important;
      z-index: 5;
      &__toggle{
        display: block;
        position: absolute;
        right: 30px;
        top: 23px;

        .icon{
          height: 1.8em;
          color: white;
        }
      }
    }
  }
</style>
