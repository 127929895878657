<script setup>
import LegalModal from '@/components/legal/LegalModal.vue';
import { ref } from 'vue';
import YBtn from '@/components/common/form/YBtn/YBtn.vue';
import { useI18n } from "vue-i18n";
const { t } = useI18n();
const showLegal = ref(false);
function showLegalModal() {
  showLegal.value = true;
}
function updateShow(value) {
  showLegal.value = value;
}
function transformTitle(title) {
  if (!title) return '';
  return title.charAt(0) + title.slice(1).toLowerCase();
}
</script>

<template>
  <div class="footer" v-if="!$route.meta.hideFooter">
    <div class="legal-link">
      <YBtn 
          size="xl"
          color="transparent" 
          :label="transformTitle(t('use.title'))"
          :rounded="true"
          roundedSize="full"
          type="button"
          class="yauty-button"
          variant="link"
          noUnderline
          contentClass="underline-animation"
          @click="showLegalModal">
      </YBtn>
    </div>
  </div>
  <LegalModal :show="showLegal" @update:show="updateShow"/>
</template>

<style lang="scss">
.footer {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  background-color: transparent;
  //width: 400px;
  min-height: 40px;
  padding-left: calc(5dvw + 45px);
  background-color: #eff3f3;
  z-index: 3;
  .legal-link{
    font-family: 'CircularStd-Book';
  }
  .underline-animation {
    color: #c6cfcf;
    &::before {
      background-color: #c6cfcf;
    }
  }
}
</style>
