let yautyData = null;
let yautyBranding = null;
const color = null;
const nick = null;
const name = null;
const key = null;

export default class LocalStorage {
  static getKey() {
    if (localStorage.getItem('yautyData')) {
      yautyData = JSON.parse(localStorage.getItem('yautyData'));
    }
    return yautyData ? yautyData.k : null;
  }

  static getColor() { //primari
    if (localStorage.getItem('yautyBranding')) {
      yautyBranding = JSON.parse(localStorage.getItem('yautyBranding'));
    }
    return yautyBranding ? yautyBranding.color : null;
  }

  static getOrganizationId() { //primari
    if (localStorage.getItem('yautyData')) {
      yautyData = JSON.parse(localStorage.getItem('yautyData'));
    }
    return yautyData ? yautyData.organizationId : null;
  }

  static getColorSecondary() { //selected
    if (localStorage.getItem('yautyBranding')) {
      yautyBranding = JSON.parse(localStorage.getItem('yautyBranding'));
    }
    return yautyBranding ? yautyBranding.secondary_color : null;
    // return '#06FF28';
  }
  static getColorSelected() { //selected
    if (localStorage.getItem('yautyBranding')) {
      yautyBranding = JSON.parse(localStorage.getItem('yautyBranding'));
      let selectedColor = yautyBranding.secondary_color;
      let opacity = 0.35;
      let r = parseInt(selectedColor.slice(1, 3), 16),
          g = parseInt(selectedColor.slice(3, 5), 16),
          b = parseInt(selectedColor.slice(5, 7), 16);
          return `rgba(${r}, ${g}, ${b}, ${opacity})`;
    }
    return yautyBranding ? yautyBranding.secondary_color : null;
  }
  static getColorSelectedHover() { //selected
    if (localStorage.getItem('yautyBranding')) {
      yautyBranding = JSON.parse(localStorage.getItem('yautyBranding'));
      let selectedColor = yautyBranding.secondary_color;
      let opacity = 0.15;
      let r = parseInt(selectedColor.slice(1, 3), 16),
          g = parseInt(selectedColor.slice(3, 5), 16),
          b = parseInt(selectedColor.slice(5, 7), 16);
          return `rgba(${r}, ${g}, ${b}, ${opacity})`;
    }
    return yautyBranding ? yautyBranding.secondary_color : null;
  }

  static getColorTertiary() { //el segon del degradat de la navbar
    if (localStorage.getItem('yautyBranding')) {
      yautyBranding = JSON.parse(localStorage.getItem('yautyBranding'));
    }
    // return '#023614';
    return yautyBranding ? yautyBranding.tertiary_color : null;
  }

  static getUsername() {
    if (localStorage.getItem('yautyData')) {
      yautyData = JSON.parse(localStorage.getItem('yautyData'));
    }
    return yautyData ? yautyData.name : null;
  }

  static getUserNick() {
    if (localStorage.getItem('yautyData')) {
      yautyData = JSON.parse(localStorage.getItem("yautyData"));
    }
    return yautyData ? yautyData.name.charAt(0).toUpperCase() : null;
  }

  static getUserEmail() {
    if (localStorage.getItem('yautyData')) {
      yautyData = JSON.parse(localStorage.getItem("yautyData"));
    }
    return yautyData ? yautyData.email : null;
  }

  static getUserLogo() {
    if (localStorage.getItem('yautyBranding')) {
      yautyBranding = JSON.parse(localStorage.getItem("yautyBranding"));
    }
    return yautyBranding ? yautyBranding.logo : null;
  }

  static getLoginTimeOut() {
    if (localStorage.getItem('yautyData')) {
      yautyData = JSON.parse(localStorage.getItem("yautyData"));
    }
    return yautyData ? yautyData.time : null;
  }

  static getUserId() {
    if (localStorage.getItem('yautyData')) {
      yautyData = JSON.parse(localStorage.getItem("yautyData"));
    }
    return yautyData ? yautyData.uid : null;
  }

  static getStoredData() {
    if (localStorage.getItem('yautyData')) {
      yautyData = JSON.parse(localStorage.getItem('yautyData'));
    }
    return yautyData || null;
  }

  static isNorthgate() {
    if (localStorage.getItem('yautyData')) {
      yautyData = JSON.parse(localStorage.getItem("yautyData"));
    }
    return yautyData ? yautyData.isNorthgate : false;
  }
  static tokenHasExpired(){
    if (localStorage.getItem('yautyData')) {
      yautyData = JSON.parse(localStorage.getItem("yautyData"));
    }
    return yautyData && yautyData.tokenExpires ? yautyData.tokenExpires - new Date().getTime() <= 0 : true;
  }
  static isTokenAlive(){
    return !this.tokenHasExpired();
  }
  static getSessionExpiration() {
    if (localStorage.getItem('yautyData')) {
      yautyData = JSON.parse(localStorage.getItem('yautyData'));
    }
    return yautyData && yautyData.tokenExpires
  }
  static getBranding() {
    let yautyBranding;
    if (localStorage.getItem('yautyBranding')) {
      yautyBranding = JSON.parse(localStorage.getItem("yautyBranding"));
    }
    return yautyBranding ?? null;
  }
}
