<script setup>
import { ref, reactive, onMounted, watch } from "vue";
import { useI18n } from 'vue-i18n';
import { useRouter } from "vue-router";
import { Popover } from 'bootstrap';
import FormController from "@/controller/FormController.vue";
import Error from "@/components/common/errors/Error.vue";
import MazInput from 'maz-ui/components/MazInput';
import YBtn from '@/components/common/form/YBtn/YBtn.vue';
import { registerUser } from '@/services/api/Users.js';
import { useToast } from 'maz-ui'
const props = defineProps({
    email: String,
    emailDisabled: Boolean,
    password: String,
    passwordConfirm: String,
    passwordMatch: Boolean,
    passwordValid: Boolean,
    userColor: String
});
const envelope = '/assets/login/icons/envelope.svg';
const lock = '/assets/login/icons/lock.svg';
const passwordValidityPopover = ref(null);
const { t } = useI18n();
const emailDisabled = ref(false);
const email = ref('');
const password = ref('');
const passwordConfirm = ref('');
const router = useRouter();

const showForm = ref(true);
const errorMsg = ref('');
const okMsg = ref('');
const showError = ref(false);
const showOk = ref(false);
const loaderButton = ref(false);
const disabledButton = ref(true);
const errorEmail = ref(false);
const errorPasssword = ref(false);
const okPasswordFormatIcon ="fa-solid fa-check";
const koPasswordFormatIcon = "fa-solid fa-xmark";
const updatePassInfoText = ref(1);
const passwordFormatRules = reactive({
    min: false,
    upper: false,
    lower: false,
    number: false,
    special: false
})
const i18n = useI18n();
const defaultLocale = ref(i18n.locale);
const passwordValid = ref(false);
const emailValid = ref(false);
const passwordMatch = ref(false);
const popoverHtml = reactive({passwordValidity: ''});

const path =  router.currentRoute.value.params.id;
const toast = useToast()
function showToast (type, msg, options) {
    let toastMessage;
    if(type == 'error') {
        toastMessage = toast.error(msg, options);
    } else {
        toastMessage = toast.success(msg, options);
    }
    setTimeout(() => {
      toastMessage.close()
    }, options.timeout)
}
function checkPasswordFormat(newVal) {
    password.value = newVal;
    passwordFormatRules.min = FormController.minRule(newVal, 8);
    passwordFormatRules.upper = FormController.uppperRule(newVal);
    passwordFormatRules.lower = FormController.lowerRule(newVal);
    passwordFormatRules.number = FormController.numberRule(newVal);
    passwordFormatRules.special = FormController.specialRule(newVal);
    updatePassInfoText.value = updatePassInfoText.value + 1;

    buildPasswordInfo();
    checkPasswordMatch(passwordConfirm.value);
    console.log('passwordValid', FormController.validPassword(newVal));
    return passwordValid.value = FormController.validPassword(newVal);
}
function validateEmail(newVal) {
    return emailValid.value = FormController.validEmail(newVal);
}
function checkPasswordMatch(newVal){
    passwordConfirm.value = newVal;
    passwordMatch.value = password.value == newVal;
    return passwordMatch.value;
}
function register() {
    showError.value = false;
    errorMsg.value = "";

    loaderButton.value = true;
    disabledButton.value = "";

    errorEmail.value = false;
    errorPasssword.value = false;

    if (checkRegister()) {
        loaderButton.value = true;
        disabledButton.value = "disabled";
        doRegister();
    } else {
        loaderButton.value = false;
        disabledButton.value = false;
        showError.value = true;
    }
}
function checkRegister() {
    errorEmail.value = false;
    errorEmail.value = false;
    let ok = true;
    if (FormController.notEmpty(email.value)) {
        errorMsg.value = t("field.fill_all_fields");
        errorEmail.value = true;
        ok = false;
    } 
    if(FormController.notEmpty(password.value)) {
        errorMsg.value = t("field.fill_all_fields");
        errorPasssword.value = true;
        ok = false;
    } 
    return ok;
}
function doRegister() {
    registerUser({ email: email.value, password: password.value, invitation: path  })
    .then((res) => {
        showError.value = true;
        loaderButton.value = false;
        disabledButton.value = false;
        let msgError = '';
        if (res && res.errors) {
            res.errors.forEach((element) => {
                if (element.field == "email") {
                    errorEmail.value = true;
                    msgError += t('error.' + element.error);
                } else if (element.field == "password" && element.error == "INVALID_PARAMETER"){
                    errorPasssword.value = true;
                    msgError += t('register.valid_password');
                } else if (element.field == "password") {
                    errorPasssword.value = true;
                    msgError += t('error.' + element.error);
                }
            });
            errorMsg.value = msgError;
            showToast('error', msgError, { position: 'top-right', timeout: 5000 });
        } else {
            showError.value = false;
            showOk.value = true;
            showToast('success', t('register.register_success'), { position: 'top-right', timeout: 5000 })
            loaderButton.value = false;
            disabledButton.value = true;
            showForm.value = false;
        }
    })
    .catch((err) => {
        showError.value = true;
        errorMsg.value = err;
        showToast('error', err, { position: 'top-right', timeout: 5000 })
        loaderButton.value = false;
        disabledButton.value = false;
    });
}
function buildPasswordInfo() {
    let passInfoTexts = {
        min: t('register.length_rule'),
        upper: t('register.uppercase_rule'),
        lower: t('register.lowercase_rule'),
        number: t('register.number_rule'),
        special: t('register.special_rule')
    };
    let html = '<div class="password-format" id="pw-format">';
    const keys = Object.keys(passInfoTexts);
    keys.forEach((key) => {
        html += `<div class="password-format-rule">
                    <div class="${!passwordFormatRules[key] ? 'color-red': 'color-green'} password-format__icon">
                       <span>${passwordFormatRules[key] ? '&#x1F5F8' : '&#x10102'}</span>
                    </div>
                    <div class="password-format__label">
                        ${passInfoTexts[key]}
                    </div>
                </div>`;
    });      
    html += '</div>';
    popoverHtml.passwordValidity = html;
    let passPopover = Popover.getInstance(passwordValidityPopover.value);
    passPopover.update();
}
function onChangeInput(el){
    validateEmail(email.value);
}
watch(defaultLocale, () => {
    checkPasswordFormat('');
});
onMounted(() => {
    checkPasswordFormat('');
});

function onKeyUpHandle(){
    console.log('handling keyup');
}
function goHome(){
  router.push('/login');
}
</script>

<template>
    <div class="">
        <template v-if="showForm">
            <form @submit.prevent="register">
                <div class="form-group pt-3">
                    <MazInput
                        v-model="email"
                        :label="t('field.email')"
                        class="yauty-input"
                        autocomplete="off"
                        size="sm"
                        rounded-size="full"
                        contentClass="yauty-button"
                        :error="errorEmail"
                        :valid-button="emailValid"
                        v-on:keyup="emailValid = validateEmail($event.target.value)"
                        >
                        <template #left-icon>
                            <img :src="envelope" alt="" style="width:16px;">
                        </template>
                    </MazInput>                    
                </div>
    
                <div class="form-group pw">
                    <MazInput
                        v-model="password"
                        name="password"
                        :label="t('field.password')"
                        class="yauty-input"
                        autocomplete="off"
                        type="password"
                        size="sm"
                        rounded-size="full"
                        :error="errorPasssword"
                        :valid-button="passwordValid"
                        v-on:keyup="passwordValid = checkPasswordFormat($event.target.value)"
                        >
                        <template #left-icon>
                            <img :src="lock" alt="" style="width:14px;">
                        </template>
                    </MazInput>                       
                    <span
                        v-popoverr 
                        data-bs-toggle="popoverr" 
                        data-bs-trigger="hover"
                        :data-bs-content="popoverHtml.passwordValidity"
                        data-bs-placement="right"
                        data-bs-html="true"
                        class="password-info"
                        :key="updatePassInfoText"
                        ref="passwordValidityPopover">
                        <font-awesome-icon icon="fa-regular fa-circle-question"></font-awesome-icon>
                    </span>
                </div>
                <div class="form-group">
                    <MazInput
                        v-model="passwordConfirm"
                        name="passwordConfirm"
                        :label="t('field.password_confirm')"
                        class="yauty-input"
                        autocomplete="off"
                        type="password"
                        size="sm"
                        rounded-size="full"
                        :error="errorPasssword"
                        :valid-button="passwordMatch && passwordValid"
                        v-on:keyup="passwordMatch = checkPasswordMatch($event.target.value)"
                        >
                        <template #left-icon>
                            <img :src="lock" alt="" style="width:14px;">
                        </template>
                    </MazInput>
                </div>
    
                <div class="form-group pt-4">
                    <YBtn 
                        size="sm"
                        color="primary" 
                        :loading="loaderButton"
                        :disabled="!emailValid || !passwordValid || !passwordMatch"
                        :label="t('register.create')"
                        :rounded="true"
                        roundedSize="full"
                        type="button"
                        class="yauty-button"
                        @click="register">
                    </YBtn>
                </div>
    
                <div class="login-pd" v-if="$route.name == 'register'">
                    <label @click="goHome" style="padding-right:1px"> {{ $t("login.login_return") }}</label>
                </div> 

            </form>
            <div class="error-container" v-if="showError">
                <Error :text="errorMsg" size="small" />
            </div>
        </template>
        <template v-else>
            <div class="ok-container" v-if="showOk">
                <div class="login-pd ok" v-if="$route.name == 'register' || $route.name == 'invitations'">
                    <label @click="goHome" style="padding-right:1px"> {{ $t("login.login_return") }}</label>
                </div> 
            </div>

        </template>
    </div>
</template>
<style lang="scss" scoped>
    #pw-format.password-format{
        // min-width: 300px;
    }
    .password-info{
        .password-format-rule{
            display: flex;
            justify-content: start;
            gap: 5px;
            font-family: 'CircularStd-Book';
        }
        svg{
            color: #888888;
        }
    }
    .color-red{
        color: red;
        width: 10px;
    }
    .color-green{
        color: green;
        width: 10px;
    }
    .yauty-input{
        width: 338px;
        * {
        font-family: 'CircularStd-Book';
        font-size: 16px;
        }
    }
    .yauty-button{
        width: 240px;
    }
    .form-group.pw{
        display: flex;
        align-items: center;
        gap: 5px;
        span{
            font-size: 12px;
        }
    }
    .login-pd{
        &.ok{
            padding-top: 5rem;
        }
        margin-top: 20px;
        label{
        text-align: left;
        text-decoration: underline;
        font: normal normal normal 17px/29px 'CircularStd-Book';
        letter-spacing: -0.85px;
        color: #000000;
        opacity: 1;
        position: relative;
        overflow: hidden;
        display: inline-block;
        text-decoration: none;
        }
        label:after {
        content: "";
        position: absolute;
        bottom: 0;
        left: -100%;
        width: 100%;
        height: 1px;
        background: #333;
        transition: left .8s;
        }
        label:hover:after {
        left: 0;
        }
        label:hover{
        cursor: pointer;
        }
    }
    div.password-format-rule {
        display: flex;
        align-items: center;
        justify-content: flex-start;
        gap: 5px;
    }
    .popover {
        min-width: 355px;
    }
</style>