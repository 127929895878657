<script setup>
import { useI18n } from "vue-i18n";
import YautyModal from '@/components/common/modals/YautyModal.vue';
import YBtn from "../form/YBtn/YBtn.vue";
const { t } = useI18n();
const props = defineProps({
  show: Boolean
});

const modalWidth = '40%';
const modalHeight = '30dvh';   
const emit = defineEmits(["closeModal"]);
function closeModal() {
  emit('closeModal');
}

</script>

<template>
    <transition name="modal">
        <YautyModal v-if="props.show"
            :width="modalWidth"
            :height="modalHeight"
            :class="'session-modal'"
            :persistent="true"
            @closeModal="closeModal"
            >
            <template v-slot:header>
                <div class="d-block">
                    <div class="session-expired-modal-title">
                        <h5>{{ $t("session.session_expired") }}</h5>
                    </div>
                </div>
            </template>
            <template v-slot:body>
                <div>{{ $t("session.expiredMessage") }}</div>
            </template>
            <template v-slot:footer>
                <div class="modal-actions">
                    <YBtn secondary :label="$t('field.close')" @click="closeModal" :rounded="true" roundedSize="full"></YBtn>
                </div>
            </template>
        </YautyModal>
    </transition>
</template>

<style lang="scss">
.session-modal{
    .modal-container{
        display: flex;
        flex-direction: column;
        border-radius: 15px;
        padding: 0;
    }
    .modal-header{
        border-bottom: 1px solid #dee2e6;
        padding: 1rem;
    }
    .modal-body{
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 1rem;
        flex-grow: 1;
    }
    .modal-footer{
        border-top: 1px solid #dee2e6;
        padding: 1rem;
        margin: 0 !important;
        .modal-actions{
            display: flex;
            align-items: center;
            justify-content: end;
        }
    }
}
.session-expired-modal-title{
    h5{
        margin-bottom: 0;
        font-weight: bold;
    }
}

</style>