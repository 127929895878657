<script setup>
import { useI18n } from "vue-i18n";

const { t } = useI18n();

const props = defineProps({
  width: String,
  height: String,
  class: String,
  persistent: Boolean,
});

</script>

<template>
  <div class="modal__template" :class="[(props.class ?? '')]">
    <div class="modal-mask">
      <div class="modal-wrapper" @click="props.persistent || $emit('closeModal')">
        <div class="modal-container" @click.stop="">
          <div class="modal-header">
            <slot name="header"> default header </slot>
          </div>

          <div class="modal-body">
            <slot name="body"> default body </slot>
          </div>

          <div class="modal-footer">
            <slot name="footer"> </slot>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.modal-template *{
  font-family: 'CircularStd-Book' !important;
}
.modal-mask {
  position: fixed;
  z-index: 9998;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: table;
  transition: opacity 0.3s ease;
}

.modal-wrapper {
  display: table-cell;
  vertical-align: middle;
}

.modal-container {
  width: v-bind(width);
  height: v-bind(height);
  margin: 0px auto;
  padding: 20px 35px;
  background-color: #fff;
  border-radius: 2px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.33);
  transition: all 0.3s ease;
  font-family: 'CircularStd-Book' !important;
}

.modal-header h3 {
  margin-top: 0;
  color: #42b983;
  font-family: 'CircularStd-Book' !important;
}

.modal-body,
.modal-footer {
  margin: 5px 0 15px;
}

.modal-enter-from,
.modal-leave-to {
  opacity: 0;
}

.modal-enter-active .modal-container,
.modal-leave-active .modal-container {
  -webkit-transform: scale(1.1);
  transform: scale(1.1);
}
</style>
