

export function humanized(timestamp) {
    let timeString = parseInt(timestamp) * 1000;
    return timestampToHumanizedString(timeString);
}

export function timestampToHumanizedString(timestamp, lang = navigator.language){
  
    // Get the amount of seconds between the given date and now
    const deltaSeconds = Math.round((timestamp - Date.now()) / 1000);
  
    // Array representing one minute, hour, day, week, month, year, in seconds
    const cutoffs = [60, 3600, 86400, 86400 * 7, 86400 * 30, 86400 * 365, Infinity];
  
    // Array equivalent to the above but in the string representation of the units
    const timeUnits = ["second", "minute", "hour", "day", "week", "month", "year"];
  
    // Grab the ideal cutoff unit
    const idealUnitIndex = cutoffs.findIndex(cutoff => cutoff > Math.abs(deltaSeconds));
  
    // Get the divisor to divide from the seconds. E.g. if our unit is "day" our divisor
    // is one day in seconds, so we can divide our seconds by this to get the # of days
    const divisor = idealUnitIndex ? cutoffs[idealUnitIndex - 1] : 1;
  
    // Intl.RelativeTimeFormat do its magic
    const rtf = new Intl.RelativeTimeFormat(lang, { numeric: "auto" });
    return rtf.format(Math.floor(deltaSeconds / divisor), timeUnits[idealUnitIndex]);
}