import { url } from '@/conf/conf.js';
import { middleware } from "@/services/api/Middleware.js";

export async function getUser(params) {
  let options = {
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
    body: JSON.stringify({
      validation_key: params.k,
      method: "getUser",
    }),
  };
  return middleware(url, options, ['isTokenAlive'])()
    .then((response) => response.json())
    .then((data) => data)
    .catch((error) => {
      console.error('Error:', error);
    });
}
export async function getUserNotifications(params) {
  let options = {
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
    body: JSON.stringify({
      validation_key: params.k,
      method: "getUserNotifications",
    }),
  };
  return middleware(url, options, ['isTokenAlive'])()
    .then((response) => response.json())
    .then((data) => data)
    .catch((error) => {
      console.error('Error:', error);
    });
  // return true;
}
export async function updateUser({ k, data }) {
  let options = {
    method: "POST",
    headers: {
      Accept: "application/json",
      'Content-Type': "application/json",
    },
    body: JSON.stringify({
      validation_key: k,
      data,
      method: "updateUser",
    }),
  };
  return middleware(url, options, ['isTokenAlive'])()
    .then((response) => response.json())
    .then((data) => data)
    .catch((error) => {
      console.error('Error:', error);
    });
}

export async function updateUserNotifications({ k, data }) {
  let options = {
    method: "POST",
    headers: {
      Accept: "application/json",
      'Content-Type': "application/json",
    },
    body: JSON.stringify({
      validation_key: k,
      data,
      method: "updateUserNotifications",
    }),
  };
  return middleware(url, options, ['isTokenAlive'])()
    .then((response) => response.json())
    .then((data) => data)
    .catch((error) => {
      console.error('Error:', error);
    });
}

export async function registerUser(params) {
  let options = {
    method: "POST",
    headers: {
      Accept: "application/json",
      'Content-Type': "application/json",
    },
    body: JSON.stringify({
      user_email: params.email,
      user_password: params.password,
      invitation: params.invitation,
      method: "registerUser",
    }),
  };
  return middleware(url, options, [])()
    .then((response) => response.json())
    .then((data) => data)
    .catch((error) => {
      console.error('Error:', error);
    });
}

export async function getUserBranding(data) {
  var responseCopy;
  let options = {
    method: "POST",
    headers: {
      Accept: "application/json",
      'Content-Type': "application/json",
    },
    body: JSON.stringify({
      path: data.path,
      method: "getUserBranding",
    }),
  };
  return middleware(url, options, [])()
    .then(function (response) {
      responseCopy = response.clone();
      return response.json();
    })
    .then((data) => data)
    .catch((error) => {
      if (error instanceof SyntaxError) {
        return responseCopy.text()
          .then(function (data) {
            return fixJson(data);
          });
      }
      else {
        throw error;
      }
    });
}

export async function updatePassword(params) {
  let options = {
    method: "POST",
    headers: {
      Accept: "application/json",
      'Content-Type': "application/json",
    },
    body: JSON.stringify({
      id: params.id,
      password: params.password,
      method: "updatePassword",
    }),
  };
  return middleware(url, options, [])()
    .then((response) => response.json())
    .then((data) => data)
    .catch((error) => {
      console.error('Error:', error);
    });
}

export async function createPasswordRecovery(params) {
  let options = {
    method: "POST",
    headers: {
      Accept: "application/json",
      'Content-Type': "application/json",
    },
    body: JSON.stringify({
      email: params.email,
      method: "createPasswordRecovery",
    }),
  };
  return middleware(url, options, [])()
    .then((response) => response.json())
    .then((data) => data)
    .catch((error) => {
      console.error('Error:', error);
    });
}
