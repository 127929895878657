import { Tooltip, Popover } from 'bootstrap'

export const tooltip = {
  mounted(el) {
    const tooltip = new Tooltip(el)
  }
}

export const popoverr = {
  mounted(el) {
    const popover = new Popover(el)
  }
}