<script setup>
import { useI18n } from "vue-i18n";
import { onMounted, ref } from "vue";
import LocalStorage from '@/controller/LocalStorage.js';
import LocaleSwitcher from "@/components/common/locale/LocaleSwitcher.vue";
import RegisterInnerForm from '@/components/register/new/RegisterInnerForm.vue';
const branding = ref(LocalStorage.getBranding());
const { t } = useI18n();
const i18n = useI18n();
const imgYautyPath = "../public/assets/login/login_logo.svg";
const imgLogginPath = '../public/assets/login/Claus_w.jpg';

let locale = ref(i18n.locale)
function onChangeInput(newVal) {
  locale.value = newVal;
  localStorage.setItem('yautyLanguage', newVal);
}
onMounted(() => {
  if (localStorage.getItem('yautyLanguage')) {
    locale.value = localStorage.getItem('yautyLanguage') ?? 'es';
  }
});
</script>
<template>
  <section class="register-container">
    <div class="register-section--left">
      <img :src="imgLogginPath" />
    </div>
    <div class="register-section--right">
      <div class="register-logo">
        <img :src="imgYautyPath" />
      </div>
      <div class="register-message_1">
        {{ t('register.create_account') }}
      </div>
      <div class="register-message_2">
        <!-- {{ $t("register.welcome_subtitle") }} -->
      </div>
      <div class="register-form">
        <RegisterInnerForm />
      </div>
    </div>
  </section>
  <div class="language-selector">
    <div class="language__selector">
      <LocaleSwitcher :lang="locale" @changeInput="onChangeInput" :mode="1"/>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.register-container {
  display: grid;
  grid-template-columns: 50% 50%;
  justify-content: center;
  height: calc(100vh - 60px);
  .register-section--left {
	width: 50dvw;
	height: 100dvh;
	img {
		height: 100%;
		width: 100%;
		object-fit: cover;
	}
  }
  .register-section--right{
	// padding-left: 6rem;
	margin-top: 10dvh;
	.register-message_1{
		text-align: left;
		font: normal normal normal 51px/29px 'CircularStd-Book';
		letter-spacing: -2.55px;
		color: #000000;
		margin-top: 60px;
	}
	.register-message_2{
		text-align: left;
		font: normal normal normal 18px/29px 'CircularStd-Book';
		letter-spacing: -0.9px;
		color: #000000;
		opacity: 1;
		margin-top: 28px;
	}
}
  @media (min-width: 360px) {
	grid-template-columns: 0% 100%;
	.register-section--left {
		width: 0;
		img {
			width: 0;
			object-fit: cover;
		}
	}	
	.register-section--right{
		// padding-left: 4rem;
	}
  }
  @media (min-width: 520px) {
	grid-template-columns: 40% 60%;
	.register-section--left {
		width: 40dvw;
		img {
			width: 40dvw;
			object-fit: cover;
		}
	}	
	.register-section--right{
		// padding-left: 4rem;
	}
  }
  @media (min-width: 768px) {
	grid-template-columns: 50% 50%;
	.register-section--left {
		// width: 50dvw;
		img {
			// width: 50dvw;
			object-fit: cover;
		}
	}
	.register-section--right{
		// padding-left: 6rem;
		padding-left: 1rem;
	}
  }
  @media (min-width: 992px) {
  }
  @media (min-width: 1200px) {
  }
  @media (min-width: 1400px) {
  }
}
.language-selector{
	position: absolute;
	bottom: -45px;
	right: 15px;
	width: 175px;
}
</style>
