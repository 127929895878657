<script setup>
import { ref, reactive, onMounted, watch } from "vue";
import { useI18n } from 'vue-i18n';
import { useRouter } from "vue-router";
import { Popover } from 'bootstrap';
import FormController from "@/controller/FormController.vue";
import { updatePassword } from '@/services/api/Users.js';
import MazInput from 'maz-ui/components/MazInput';
import YBtn from '@/components/common/form/YBtn/YBtn.vue';
import { useToast } from 'maz-ui'
const props = defineProps({
    password: String,
    passwordConfirm: String,
    passwordMatch: Boolean,
    passwordValid: Boolean,
    userColor: String
});

const passwordValidityPopover = ref(null);
const { t } = useI18n();
const password = ref('');
const passwordConfirm = ref('');
const router = useRouter();

const showForm = ref(true);
const errorMsg = ref('');
const okMsg = ref('');
const showError = ref(false);
const showOk = ref(false);
const loaderButton = ref(false);
const disabledButton = ref(true);
const errorPasssword = ref(false);
const okPasswordFormatIcon ="fa-solid fa-check";
const koPasswordFormatIcon = "fa-solid fa-xmark";
const updatePassInfoText = ref(1);
const passwordFormatRules = reactive({
    min: false,
    upper: false,
    lower: false,
    number: false,
    special: false
})
const i18n = useI18n();
const defaultLocale = ref(i18n.locale);
const passwordValid = ref(false);
const passwordMatch = ref(false);
const popoverHtml = reactive({passwordValidity: ''});

const path =  router.currentRoute.value.params.id;

function checkPasswordFormat(newVal) {
    password.value = newVal;
    passwordFormatRules.min = FormController.minRule(newVal, 8);
    passwordFormatRules.upper = FormController.uppperRule(newVal);
    passwordFormatRules.lower = FormController.lowerRule(newVal);
    passwordFormatRules.number = FormController.numberRule(newVal);
    passwordFormatRules.special = FormController.specialRule(newVal);
    updatePassInfoText.value = updatePassInfoText.value + 1;

    buildPasswordInfo();
    checkPasswordMatch(passwordConfirm.value);
    return passwordValid.value = FormController.validPassword(newVal);
}
function checkPasswordMatch(newVal){
    passwordConfirm.value = newVal;
    passwordMatch.value = password.value == newVal;
    return passwordMatch.value;
}
function recoverPassword() {
    if(!passwordValid) return;
    showError.value = false;
    errorMsg.value = "";
    loaderButton.value = true;
    disabledButton.value = "";
    errorPasssword.value = false;

    if (checkForm()) {
        loaderButton.value = true;
        disabledButton.value = "disabled";
        doRecoverPassword();
    } else {
        loaderButton.value = false;
        disabledButton.value = false;
        showError.value = true;
    }
}
function checkForm() {
    errorPasssword.value = false;
    let ok = true;
    if(FormController.notEmpty(password.value)) {
        errorMsg.value = t("field.fill_all_fields");
        errorPasssword.value = true;
        ok = false;
    } 
    return ok;
}
const toast = useToast()
function showToast (type, msg, options) {
    let toastMessage;
    if(type == 'error') {
        toastMessage = toast.error(msg, options);
    } else {
        toastMessage = toast.success(msg, options);
    }
    setTimeout(() => {
      toastMessage.close()
    }, options.timeout)
}
function doRecoverPassword() {
    updatePassword({ password: password.value, id: path})
    .then((res) => {
        showError.value = true;
        loaderButton.value = false;
        disabledButton.value = false;
        let msgError = '';
        if (res && res.errors) {
            res.errors.forEach((element) => {
                if (element.field == "password" && element.error == "INVALID_PARAMETER"){
                    errorPasssword.value = true;
                    msgError += t('register.valid_password');
                } else if (element.field == "password") {
                    errorPasssword.value = true;
                    msgError += t('error.' + element.error);
                } else if(element.field == 'password_recovery_id'){
                    errorPasssword.value = true;
                    // msgError += t('error.' + element.error);
                    showToast('error', t('error.' + element.error), { position: 'top-right', timeout: 5000 });
                }
            });
            errorMsg.value = msgError;
        } else {
            showError.value = false;
            showOk.value = true;
            loaderButton.value = false;
            disabledButton.value = true;
            showForm.value = false;
            showToast('success', t('register.password_recovery'), { position: 'top-right', timeout: 5000 });
        }
    })
    .catch((err) => {
        showError.value = true;
        errorMsg.value = err;
        loaderButton.value = false;
        disabledButton.value = false;
    });
}
function buildPasswordInfo() {
    let passInfoTexts = {
        min: t('register.length_rule'),
        upper: t('register.uppercase_rule'),
        lower: t('register.lowercase_rule'),
        number: t('register.number_rule'),
        special: t('register.special_rule')
    };
    let html = '<div class="password-format">';
    const keys = Object.keys(passInfoTexts);
    keys.forEach((key) => {
        html += `<div class="password-format-rule">
                    <div class="${!passwordFormatRules[key] ? 'color-red': 'color-green'} password-format__icon">
                       <span>${passwordFormatRules[key] ? '&#x1F5F8' : '&#x10102'}</span>
                    </div>
                    <div class="password-format__label">
                        ${passInfoTexts[key]}
                    </div>
                </div>`;
    });      
    html += '</div>';
    popoverHtml.passwordValidity = html;

    let passPopover = Popover.getInstance(passwordValidityPopover.value);
    passPopover.update();
}
watch(defaultLocale, () => {
    checkPasswordFormat('');
});
onMounted(() => {
    checkPasswordFormat('');
});
function goHome(){
  router.push('/login');
}
</script>

<template>
    <div class="">
        <template v-if="showForm">
            <form @submit.prevent="recoverPassword">
                <div class="form-group pw">
                    <!-- <div class="password-label-wrapper">
                        <Labelinput
                            :text="t('field.password')"
                            size="small"
                            color="#70707"
                            :bold="true"
                        >
                        </Labelinput>
                        <span
                            v-popoverr 
                            data-bs-toggle="popover" 
                            data-bs-trigger="hover"
                            :data-bs-content="popoverHtml.passwordValidity"
                            data-bs-placement="right"
                            data-bs-html="true"
                            class="password-info"
                            :key="updatePassInfoText"
                            ref="passwordValidityPopover">
                            <font-awesome-icon icon="fa-regular fa-circle-question"></font-awesome-icon>
                        </span>
                    </div>
                    <div class="recover-password-form-input-wrapper">
                        <CustomInput
                            type="password"
                            :placeholder="t('field.password')"
                            input="password"
                            :error="errorPasssword"
                            :value="password"
                            v-on:keyup="passwordValid = checkPasswordFormat($event.target.value)"
                        >
                        </CustomInput>
                        <font-awesome-icon v-if="passwordValid" :icon="passwordValid ? okPasswordFormatIcon : koPasswordFormatIcon" :class="{ 'color-red': !passwordValid, 'color-green': passwordValid}"/>
                    </div> -->
                    <MazInput
                        v-model="password"
                        name="password"
                        :label="t('field.password')"
                        class="yauty-input"
                        autocomplete="off"
                        type="password"
                        size="sm"
                        rounded-size="full"
                        :error="errorPasssword"
                        :valid-button="passwordValid"
                        v-on:keyup="passwordValid = checkPasswordFormat($event.target.value)"
                        >
                        <template #left-icon>
                            <img :src="lock" alt="" style="width:14px;">
                        </template>
                    </MazInput>                       
                    <span
                        v-popoverr 
                        data-bs-toggle="popoverr" 
                        data-bs-trigger="hover"
                        :data-bs-content="popoverHtml.passwordValidity"
                        data-bs-placement="right"
                        data-bs-html="true"
                        class="password-info"
                        :key="updatePassInfoText"
                        ref="passwordValidityPopover">
                        <font-awesome-icon icon="fa-regular fa-circle-question"></font-awesome-icon>
                    </span>
                </div>
                <div class="form-group">
                    <!-- <Labelinput
                        :text="t('field.password_confirm')"
                        size="small"
                        color="#70707"
                        :bold="true"
                    >
                    </Labelinput>
                    <div class="recover-password-form-input-wrapper">
                        <CustomInput
                            type="password"
                            :placeholder="t('field.password')"
                            input="password"
                            :error="errorPasssword"
                            :value="passwordConfirm"
                            v-on:keyup="passwordMatch = checkPasswordMatch($event.target.value)"
                            @keyup.enter="recoverPassword"
                        >
                        </CustomInput>
                        <font-awesome-icon v-if="passwordMatch && passwordValid" :icon="passwordMatch && passwordValid ? okPasswordFormatIcon : koPasswordFormatIcon" :class="{ 'color-red': !passwordMatch, 'color-green': passwordMatch}"/>
                    </div> -->
                    <MazInput
                        v-model="passwordConfirm"
                        name="passwordConfirm"
                        :label="t('field.password_confirm')"
                        class="yauty-input"
                        autocomplete="off"
                        type="password"
                        size="sm"
                        rounded-size="full"
                        :error="errorPasssword"
                        :valid-button="passwordMatch && passwordValid"
                        v-on:keyup="passwordMatch = checkPasswordMatch($event.target.value)"
                        @keyup.enter="recoverPassword"
                        >
                        <template #left-icon>
                            <img :src="lock" alt="" style="width:14px;">
                        </template>
                    </MazInput>
                </div>
    
                <div class="form-group">
                    <YBtn 
                        size="sm"
                        color="primary" 
                        :loading="loaderButton"
                        :disabled="!passwordValid || !passwordMatch"
                        :label="t('field.send')"
                        :rounded="true"
                        roundedSize="full"
                        type="button"
                        class="yauty-button"
                        @click="recoverPassword">
                    </YBtn>
                </div>
                <div class="login-pd">
                    <label @click="goHome" style="padding-right:1px"> {{ $t("login.login_return") }}</label>
                </div> 
    
                <!-- <div class="form-group text-center pt-5" v-if="$route.name == 'register'">
                    <router-link to="/login" class="form-group router-lbl" :title=' $t("login.login_return") '>
                    {{ $t("login.login_return") }}
                    </router-link>
                </div> -->
            </form>
            <div class="error-container" v-if="showError">
            </div>
        </template>
        <template v-else>
            <div class="ok-container" v-if="showOk">
                <div class="login-pd">
                    <label @click="goHome" style="padding-right:1px"> {{ $t("login.login_return") }}</label>
                </div>
            </div>

        </template>
    </div>
</template>
<style lang="scss" scoped>
    .recover-buttons{
        display: flex;
        flex-direction: column;
        gap: 0.5rem;
        align-items: center;
        justify-items: center;
    }
    .password-label-wrapper{
        display: flex;
        gap: 1rem;
    }
    .password-format{
        display: flex;
        flex-direction: column;
        font-size: 10px;
        color: #A0A0A0;

        .password-format-rule{
            display: flex;
            justify-content: space-between;
            gap: 5px;
        }
    }
    .recover-password-form-input-wrapper{
        display: flex;
        flex-direction: row;
        align-items: center;
        gap: 0.5rem;
    }
    .color-red{
        color: red;
    }
    .color-green{
        color: green;
    }
    .ok-container{
        margin-top: 2rem;
        &.w100{
            padding-right: 8rem;
        }
    }
    .yauty-input{
        width: 338px;
        * {
        font-family: 'CircularStd-Book';
        font-size: 16px;
        }
    }
    .yauty-button{
        width: 240px;
    }
    .form-group.pw{
        display: flex;
        align-items: center;
        gap: 5px;
        span{
            font-size: 12px;
        }
    }
    .login-pd{
        &.ok{
            padding-top: 5rem;
        }
        margin-top: 2rem;
        label{
            text-align: left;
            text-decoration: underline;
            font: normal normal normal 17px/29px 'CircularStd-Book';
            letter-spacing: -0.85px;
            color: #000000;
            opacity: 1;
            position: relative;
            overflow: hidden;
            display: inline-block;
            text-decoration: none;
        }
        label:after {
            content: "";
            position: absolute;
            bottom: 0;
            left: -100%;
            width: 100%;
            height: 1px;
            background: #333;
            transition: left .8s;
        }
        label:hover:after {
            left: 0;
        }
        label:hover{
            cursor: pointer;
        }
    }    
    div.password-format-rule {
        display: flex;
        align-items: center;
        justify-content: flex-start;
        gap: 5px;
    }
</style>