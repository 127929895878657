<script setup>
import { watch, ref, onMounted } from "vue";

const props = defineProps({
  time: [String, Number],
  statusLog: Object,
  type: [String, Number]
});
const color = ref(props.statusLog[0]?.statusColor);
const overTwentyFour = ref(false);
onMounted(() => {
  color.value = props.statusLog[0]?.statusColor;
  if (parseInt(props.time)/60 > 24) {
    overTwentyFour.value = true;
  }
});
</script>

<template>
    <div class="workfile-reparation-time" :style="{'background-color': color}">
      <img src="/assets/workfiles/crono_blanc.svg" />
      <div class="subtitle">
        <span class="cell-status-category-primary subtitle">{{ $t("workfiles.state.primary." + props.statusLog[0]?.status) }}</span>
      </div>
      <div class="over-twenty-four" v-if="overTwentyFour">+24</div>
    </div>
</template>

<style lang="scss">
.workfile-reparation-time{
  white-space: nowrap;
  display: inline-block;
  position: relative;
  min-width: 100px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-items: start;
  border-bottom-left-radius: 15px;
  border-bottom-right-radius: 15px;
  padding-left: 1rem;
  gap: 1rem;
  @media only screen and (max-width: 720px) {
    margin-left: 3rem;
  }
  @media only screen and (min-width: 721px) {
    margin-left: 3rem;
  }  
  @media only screen and (min-width: 1280px) {
    margin-left: 6rem;
    margin-right: 3rem;
  }
  img{
    width: 24px;
    height: 24px;
  }
  .over-twenty-four{
    display: flex;
    align-items: center;
    justify-content: center;
    width: 37px;
    height: 23px;
    border-radius: 15px;
    background-color: #FF0080;
    color: white;
    position: absolute;
    right: -5px;
    top: -10px;
    font-family: 'CircularStd-Bold';
    font-size: 14px;
  }
  .subtitle{
    width: 100%;
    color: white;
    font-size:18px;
  }
}
</style>