<script setup>
import { ref } from "vue";
import { useI18n } from 'vue-i18n';
import { useRouter } from "vue-router";
import { getInvitation, acceptInvitation } from "@/services/api/Invitations.js"
import LocalStorage from "@/controller/LocalStorage.js";

import YLoader from "../common/loaders/YLoader.vue";
import Error from "@/components/common/errors/Error.vue";
import Oks from "@/components/common/Oks/Ok.vue";

import Title from "@/stories/Labels/Titles/Title.vue";
import YBtn from "../common/form/YBtn/YBtn.vue";

import InvitationStatus from "@/components/invitation/components/InvitationStatus.vue";
import InvitationData from "@/components/invitation/components/InvitationData.vue";

const { t } = useI18n();
const userKey = ref(LocalStorage.getKey());
const router = useRouter();
const loading = ref(true);
const invitationInfo = ref(null);
const loaderButton = ref(false);
const disabledButton = ref(false);
const errorMsg = ref(false)
const showError = ref(false);
const okMsg = ref('');
const showOk = ref(false);

const path =  router.currentRoute.value.params.id;

getInvitation({ path: path }).then((res) => {
    loading.value = false;
    invitationInfo.value = res;
    return res;
});

function sendAcceptInvitation() {
    showError.value = false;
    errorMsg.value = "";
    loaderButton.value = true;
    disabledButton.value = "disabled";
    acceptInvitation({ k: userKey.value, path: path })
    .then((res) => {
        loaderButton.value = false;
        if(res && res.errors) {
            showError.value = true;
            if(res.errors[0].error == 'DOES_NOT_EXISTS') {
                errorMsg.value =  t('error.INVITATION_DOES_NOT_EXISTS');
            }
            if(res.errors[0].error == 'ALREADY_ACCEPTED') {
                errorMsg.value =  t('error.ALREADY_ACCEPTED');
            }
            disabledButton.value = false;
        } else {
            showError.value = false;
            showOk.value = true;
            okMsg.value = t('invitations.invitation_redirect');
            setTimeout(() => {
                showOk.value = false;
                router.push("/");
            }, 3000);
        }
    });
}
</script>

<template>
    <div class="w-100 invitation__wrapper">
        <div class="invitation__title">
            <div class="invitation__title_left">
                <Title :text="t('invitations.pending_invitation')" size="medium"> </Title>
            </div>
            <div class="invitation__title_right">
                <YBtn
                    primary
                    :loading="loaderButton"
                    :disabled="disabledButton"
                    :label="t('invitations.accept_invitation')"
                    :rounded="true"
                    roundedSize="full"
                    @click="sendAcceptInvitation"
                >
                </YBtn>
            </div>
        </div>

        <div class="invitation__content">
            <div class="invitation__content_msg">
                <div class="error-container mt-2 mb-4" v-if="showError">
                    <Error :text="errorMsg" size="small" />
                </div>
                <div class="activate-ok mt-2 mb-4" v-if="showOk">
                    <Oks :text="okMsg" size="small" />
                </div>
            </div>
            <div class="invitation__content_loader" v-if="loading">
                <YLoader />
            </div>
            <div class="invitation__content_info" v-else>
                <Title :text="t('invitations.invitation_info')" size="ssmall"> </Title>
                <div class="">
                    <InvitationStatus 
                        :status="invitationInfo.status"/>
                    <InvitationData 
                        :data="invitationInfo.data"/>
                </div>
            </div>
        </div>
    </div>
</template>

<style lang="scss" scoped>
.invitation__wrapper {
    display: block;
    padding: 25px 25px 20px;
    background-color: #ffffff;
    box-shadow: rgba(0, 0, 0, 0.05) 0px 6px 24px 0px, rgba(0, 0, 0, 0.08) 0px 0px 0px 1px;
    .invitation__title {
        display: flex;
        gap: 1px;
        .invitation__title_left {
            flex: 4;
        }
        .invitation__title_right {
            flex: 2;
            text-align: right;
            button {
                max-width: 250px;
            }
        }
    }
    .invitation__content {
        min-height: 200px;
        .invitation__content_loader {
            min-height: 200px;
            display: flex;
            align-items: center;
            justify-content: center;
        }
    }
}
</style>