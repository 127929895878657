<script setup>
import { onMounted, watch, ref } from "vue";
import { useI18n } from 'vue-i18n';
const { t } = useI18n();
const props = defineProps({
  actions: Array,
  outOfStock: [String, Number]
});
const sparepartsPending = t('field.missing_parts');
const outOfStock = ref(false);
watch(() => props.actions, () => {

});
onMounted(() => {
  if(props.outOfStock && props.outOfStock == 1){
    outOfStock.value = true;
  }
});
</script>

<template>
    <div class="workfile-actions">
      <div class="subtitle">
        {{ t('actions.actions') }} 
        <span
          v-if="outOfStock"
          v-popoverr 
          data-bs-toggle="popover" 
          data-bs-trigger="hover"
          :data-bs-content="sparepartsPending"
          data-bs-placement="right"
          data-bs-html="false" 
          class="spareparts-pending">
          <font-awesome-icon icon="fa-solid fa-circle-exclamation"/>
        </span>
      </div>
      <div class="action-list">
        <div class="action-row" v-for="action in props.actions">
          <!-- <font-awesome-icon icon="fa-solid fa-chevron-right" /> -->
          <div class="action-description">{{ action.description }}</div>
          <!-- <font-awesome-icon icon="fa-solid fa-circle-xmark"/> -->
        </div>
      </div>
    </div>
</template>

<style lang="scss">
.workfile-actions{
  padding: 4rem;
  @media only screen and (max-width: 998px) {
    padding: 2rem;
    margin-bottom: 0.5rem;
  }
  @media only screen and (min-width: 1680px) {
    padding: 4rem;
  }
  .subtitle{
    margin-bottom: 1.5rem;
    padding-left: 1rem;
    font: normal normal normal 22px/19px 'CircularStd-Bold';
    letter-spacing: 0px;
    color: #153440;
    opacity: 1;
    .spareparts-pending{
      color: #E29722;
    }
  }
  .action-list{
    display: flex;
    flex-direction: column;
    gap: 1rem;
    .action-row{
      background-color: #F2F2F2;
      border: 1px solid #F2F2F2;
      border-radius: 23px;
      opacity: 1;
      padding-left: 1rem;
      padding-right: 1rem;
      height: 32px;
      display: flex;
      align-items: center;
      justify-content: start;
      gap: 1rem;
      .action-description{
        font: normal normal normal 20px/19px 'CircularStd-Light';
        letter-spacing: 0px;
        color: #000000;
        opacity: 1;
        flex-grow: 1;
        font-size: 16px;
      }
    }
    // .action-row > :first-child,
    // .action-row > :last-child{
    //   color: #ACB4C1;
    // }
  }
}
</style>