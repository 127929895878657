import { defineStore, acceptHMRUpdate } from 'pinia';

export const useSidenavbarStore = defineStore({
    id: "sidenavbar",
	state: () => ({ 
        isCollapse: true,
    }),
    getters: {
        getSidenavbar(state){
            return state.getSidenavbar
        }
    },
    actions: {
        changeCollapse() {
            this.isCollapse = !this.isCollapse
        }
    }
});

if (import.meta.hot) {
    import.meta.hot.accept(acceptHMRUpdate(useSidenavbarStore, import.meta.hot))
}