<script setup>
import { watch, ref, onMounted } from "vue";
import { useI18n } from 'vue-i18n';
const { t } = useI18n();
const props = defineProps({
  modified: String,
  delivery: String,
  reparation: String,
  schedule: String,
  type: [String, Number],
  reparationTime: [String, Number]
});
const deliveryDate = ref('');
const reparationDate = ref('');
const scheduleDate = ref('');
const modifiedDate = ref('');
const reparationTime = ref('');
// watch([() => props.schedule, () => props.reparation, () => props.delivery, () => props.modified], ([newSchedule, newReparation, newDelivery, newModified]) => {

// })
function formatDate(timestamp) {
  let date = new Date(timestamp);
  let year = date.getUTCFullYear();
  let month = String(date.getUTCMonth() + 1).padStart(2, '0');
  let day = String(date.getUTCDate()).padStart(2, '0');
    let hours = String(date.getUTCHours()).padStart(2, '0');
  let minutes = String(date.getUTCMinutes()).padStart(2, '0');
  return `${day}/${month}/${year} ${hours}:${minutes}`;
}
onMounted(()=>{
  if(props.modified != '' && props.modified != null && parseInt(props.modified) > 0){
    modifiedDate.value = new Date(parseInt(props.modified) * 1000).toLocaleString([], {
      day: "2-digit",
      month: "2-digit",
      year: "numeric",
      hour: "2-digit",
      minute: "2-digit",
    }).replace(',', '');
  }
  if(props.delivery != '' && props.delivery != null && parseInt(props.delivery) > 0){
    deliveryDate.value = formatDate(parseInt(props.delivery) * 1000);
  }
  if(props.reparation != '' && props.reparation != null && parseInt(props.reparation) > 0){
    reparationDate.value = new Date(parseInt(props.reparation) * 1000).toLocaleString([], {
      day: "2-digit",
      month: "2-digit",
      year: "numeric",
      hour: "2-digit",
      minute: "2-digit",
    }).replace(',', '');
  }
  if(props.schedule != '' && props.schedule != null && parseInt(props.schedule) > 0){
    scheduleDate.value = formatDate(parseInt(props.schedule) * 1000);
  }
  if(props.reparationTime != '' && props.reparationTime != null && parseInt(props.reparationTime) > 0){
    let minutes = parseInt(props.reparationTime) % 60;
    let hours = (parseInt(props.reparationTime) - minutes) / 60;
    reparationTime.value = `${hours}h ${minutes}m`;
  }
})

</script>

<template>
  <div class="workfile-dates-wrapper">
    <div class="workfile-dates">
        <div class="workfile-dates-content" v-if="props.type == 1">
            <div class="workfile-dates-content-item">
                <div class="subtitle">{{ t('field.modified') }}</div>
                <span>{{ modifiedDate }}</span>
            </div>
        </div>
        <div class="workfile-dates-content" v-else>
            <div class="workfile-dates-content-item" v-if="scheduleDate">
                <div class="subtitle">{{ t('appointment.appointment') }}</div>
                <span>{{ scheduleDate }}</span>
            </div>
            <div class="workfile-dates-content-item" v-if="reparationDate">
                <div class="subtitle">{{ t('field.reparation_date') }}</div>
                <span>{{ reparationDate }}</span>
            </div>
            <div class="workfile-dates-content-item" v-if="deliveryDate">
                <div class="subtitle">{{ t('field.delivery_date') }}</div>
                <span>{{ deliveryDate }}</span>
            </div>
        </div>
    </div>
    <div class="workfile-reparation-hours" v-if="reparationTime">
      <div class="workfile-reparation-title">
            <span class="subtitle">{{ t('workfiles.state.3') }}</span>
      </div>
      <div class="workfile-dates-content">
          <div class="workfile-dates-content-item">
              <span>{{ reparationTime }}</span>
          </div>
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.workfile-dates-wrapper{
  display: flex;
  flex-direction: column;
  .workfile-dates-content-item{
    margin-bottom: 6px;
    span{
      font-size: 14px;
    }
  }
  .workfile-reparation-hours{
    margin-top: -5px;
  }
  @media only screen and (max-width: 720px) {
    flex-direction: row;
    gap: 1rem;
    margin-left: 1rem;
  }
  @media only screen and (max-width: 1279px) {
    flex-direction: row;
    gap: 1rem;
    margin-left: 1rem;
    margin-bottom: 1rem;
  }
}
.workfile-dates{
  @media only screen and (max-width: 1279px) {
    padding-left:2rem;
  }
  .subtitle{
    font-family: 'CircularStd-Bold';
    color: #153440;
    opacity: 0.42;
    font-size: 14px;
  }
  .workfile-dates-content{
    font: normal normal normal 16px/19px 'CircularStd-Book';;
    color: #000000;
    opacity: 1;
    letter-spacing: 0px;
    line-height: 1.2;
  }
}
</style>