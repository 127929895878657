<script setup>
import { ref } from "vue";
import { useI18n } from 'vue-i18n';
import { useRouter } from "vue-router";

import RegisterInnerForm from '@/components/register/new/RegisterInnerForm.vue';
import LoginYauty from '@/components/login/LoginYauty.vue';
import YLoader from "../../common/loaders/YLoader.vue";
import Error from "@/components/common/errors/Error.vue";
import LocalStorage from "@/controller/LocalStorage.js";
import logo from '../../../../public/assets/logo-yauty-color-2.png';
// import invitationImage from '../../../../public/assets/invitations/invitations_road.jpg';
const imgYautyPath = "../public/assets/login/login_logo.svg";
const imgLogginPath = '../public/assets/login/road_web.jpg';
import YBtn from '@/components/common/form/YBtn/YBtn.vue';
import { getInvitation } from '@/services/api/Invitations.js';

const { t } = useI18n();
const router = useRouter();
const invitationLoader = ref(true);
const invitationError = ref(false)
const email = ref(null)
const userEmail = ref(LocalStorage.getUserEmail());
const isSameUser = ref(false)
const userLogo = ref(logo);
const userColor = ref("");

let invitationData = ref(false);
let path = router.currentRoute.value.params.id

getInvitation({
        path: path,
    })
    .then((res) => {
        invitationLoader.value = false;
        if (res.data) {
            invitationData.value = res
            if(res.data.invitationEmail == userEmail.value) {
                isSameUser.value = true;
                setTimeout(() => {
                    router.push("/");
                }, 3000);
            } else {
                email.value = res.data.invitationEmail;
                if(res.logo && res.logo != '') {
                    userLogo.value = res.logo;
                }
                if(res.color && res.color != '') {
                    userColor.value = res.color;
                }
            }
            return res.data;
        } else if(res.errors) {
            if(res.errors[0].error == 'DOES_NOT_EXISTS') {
                invitationError.value =  t('error.INVITATION_DOES_NOT_EXISTS');
            }
        }
        
    return 0;
    })
    .catch((err) => {
        console.log(err);
    });
</script>

<template>
    <section class="register-container">
        <div class="register-section--left">
        <img :src="imgLogginPath" />
        </div>
        <div class="register-section--right">
        <div class="register-logo">
            <img :src="imgYautyPath" />
        </div>
        <div class="register-message_1">
            {{ t('register.welcome') }}
        </div>
        <div class="register-message_2">
            {{ $t("register.welcome_sub") }}
        </div>
        <div class="register-form">
            <div class="invitation__loader" v-if="invitationLoader">
                <YLoader />
            </div>
            <div class="" v-else>
                <div class="invitation__error pt-3" style="margin-right: 4rem;" v-if="invitationError">
                    <Error :text="invitationError" size="small" />
                    <div class="form-group text-center pt-5">
                        <YBtn 
                            size="sm"
                            to="/register"
                            color="info" 
                            :label="t('register.create_account')"
                            :rounded="true"
                            roundedSize="full"
                            class="yauty-button">
                        </YBtn>
                    </div>
                </div>
                <div class="invitation__logged" v-else-if="isSameUser">
                    {{ t('invitations.invitation_redirect') }}
                </div>
                <div class="invitation__logged" v-else-if="invitationData.status==1">
                    {{ t('error.ALREADY_ACCEPTED') }}
                </div>
                <div class="invitation__form" v-else>
                    <div class="" v-if="invitationData.userId">
                        <LoginYauty 
                            :email="email"
                            emailDisabled="true" 
                        />
                    </div>
                    <div class="" v-else>
                        <RegisterInnerForm
                            :email="email"
                            :userColor="userColor"
                            emailDisabled="true" 
                        />
                    </div>
                </div>
            </div>
        </div>
        </div>
    </section>
    <div class="language-selector">
        <div class="language__selector">
        <LocaleSwitcher :lang="locale" @changeInput="onChangeInput" :mode="1"/>
        </div>
    </div>    
</template>

<style lang="scss" scoped>
.register-container {
  display: grid;
  grid-template-columns: 50% 50%;
  justify-content: center;
  height: calc(100vh - 60px);
  .register-section--left {
	width: 50dvw;
	height: 100dvh;
	img {
		height: 100%;
		width: 100%;
		object-fit: cover;
	}
  }
  .register-section--right{
	padding-left: 7rem;
	margin-top: 10dvh;
	.register-message_1{
		text-align: left;
		font: normal normal normal 51px/29px 'CircularStd-Book';
		letter-spacing: -2.55px;
		color: #000000;
		margin-top: 60px;
	}
	.register-message_2{
		text-align: left;
		font: normal normal normal 18px/29px 'CircularStd-Book';
		letter-spacing: -0.9px;
		color: #000000;
		opacity: 1;
		margin-top: 28px;
	}
	}
  @media (min-width: 360px) {
	grid-template-columns: 0% 100%;
	.register-section--left {
		width: 0;
		img {
			width: 0;
			object-fit: cover;
		}
	}	
	.register-section--right{
		padding-left: 4rem;
	}
  }
  @media (min-width: 520px) {
	grid-template-columns: 40% 60%;
	.register-section--left {
		width: 40dvw;
		img {
			width: 40dvw;
			object-fit: cover;
		}
	}	
	.register-section--right{
		padding-left: 4rem;
	}
  }
  @media (min-width: 768px) {
	grid-template-columns: 50% 50%;
	.register-section--left {
		width: 50dvw;
		img {
			width: 50dvw;
			object-fit: cover;
		}
	}
	.register-section--right{
		padding-left: 7rem;
	}
  }
  @media (min-width: 992px) {
  }
  @media (min-width: 1200px) {
  }
  @media (min-width: 1400px) {
  }
}
.language-selector{
	position: absolute;
	bottom: -45px;
	right: 15px;
	width: 175px;
}
.invitation__loader{
    margin-top: 3rem;
}
.error__container{
    margin-right: 4rem !important;
}
</style>