<script setup>
import { getStatusText, matchStatusToSvg } from "@/services/api/Mappings.js";
import YBtn from "../../../common/form/YBtn/YBtn.vue";
const pdf = '/assets/svg/pdf.svg';
const props = defineProps({
  status: Object,
  type: [String, Number]
});
function formatDate(timestamp) {
  return new Date(timestamp).toLocaleString([], {
    day: "2-digit",
    month: "2-digit",
    year: "numeric",
    hour: "2-digit",
    minute: "2-digit",
  }).replace(',', '');
}
</script>
<template>
    <div class="workfile-status-item">
        <div class="status-image-wrapper">
            <div class="status-image" :style="{'background-color': props.status.statusColor}">
                <img v-if="props.type == 1 && [0,1,2,3,4,5,6,99,101,102].includes(props.status?.status)" v-bind:src="'/assets/workfiles/99.svg'" class="quotation-img" />
                <img v-else v-bind:src="'/assets/workfiles/'+ matchStatusToSvg(props.status?.status) +'.svg'" />
            </div>
            <div class="dash-wrapper">
                <div class="dash"></div>
            </div>
        </div>
        <div class="status-text">
            <span class="cell-status-category-primary subtitle">{{ $t(getStatusText(props.status?.status, 'primary', props.type)) }}</span>
            <span class="cell-status-category-secondary">{{ $t(getStatusText(props.status?.status, 'secondary', props.type)) }}</span>
            <span class="cell-status-category-date">{{ props.status?.created != '' ? formatDate(props.status?.created * 1000) : '' }}</span>
            <YBtn :href="props.status.link" style="font-size: 20px;width: 33px;justify-content: center;height: 14px;border-radius: 10px;margin-top: 5px;" v-if="props.status.link" variant="link" :noUnderline="true">
              <div class="item-document">PDF</div>
            </YBtn>
            <div class="item-document-empty" v-else></div>
      </div>        
    </div>
</template>

<style lang="scss">
.workfile-status-item{
  padding-top: 2rem;
  display: flex;
  gap: 10px;
  .status-text{
    display: flex;
    flex-direction: column;
    line-height: 1.1;
    a{
        text-decoration: none;
      }
    .item-document{
        width: 33px;
        height: 14px;
        border-radius: 10px;
        display: flex;
        justify-content: center;
        align-items: center;
        background-color: #B6BDC9;
        font-size: 11px;
        color: white;
        &:hover {
          background-color: #737C8D;
          transition: 0.3s linear;
        }
    }
    .cell-status-category-secondary{
        color: #000000;
        opacity: 0.52;
        font-family: 'CircularStd-Light';
        font-size: 14px;
    }
    .cell-status-category-date{
        font-family: 'CircularStd-Book';
        color: #000000;
        opacity: 1;
        font-size: 14px;
    }
  }
  .status-image-wrapper{
    .status-image{
        width: 40px;
        height: 40px;
        border-radius: 50%;
        display: flex;
        justify-content: center;
        align-items: center;
        img{
            width: 25px;
            height: 25px;
        }
        .quotation-img{
          margin-left: -7px;
        }
    }
    .dash-wrapper{
        display: flex;
        justify-content: center;
        height: 110%;
        .dash {
            height: 100%;
            width: 2px;
            border: 0;
            background-image: linear-gradient(to bottom, #C8C8C8 50%, transparent 50%);
            background-repeat: repeat-y;
            background-size: 100% 7px;
        }
    }
  }

  .subtitle{
    font-family: 'CircularStd-Bold';
    color: #000000;
    letter-spacing: 0px;
    font-size: 15px;
  }
}
</style>