<script setup>
import { ref } from "vue";
import LocalStorage from '@/controller/LocalStorage.js';

const props = defineProps({
  total: Number,
  pagNumber: Number,
  limit: Number,
});

const emit = defineEmits("loadPage");
const pagLimit = ref(props.limit ?? 12);
const numPages = Math.ceil(parseInt(props.total) / pagLimit.value); // by now
const userColor = ref(LocalStorage.getColor());
const pagesShow = numPages>5?ref([1, 2, 3, 4, 5]):ref(numPages);
const pagesEnd = ref([ numPages ]);
const pagesMiddle = ref([]);

function goToPage(index) {
  if ((index == numPages) || (index > numPages)) {
    goToLastPage();
  } else if (index == pagesEnd.value[0] && pagesEnd.value.length >= 5) {
    middleLoadPage(index);
  } else if (pagesMiddle.value.includes(index)) {
    middleLoadPage(index);
  } else if (index == pagesShow.value[0]) {
    goToFirstPage();
  } else {
    emit('loadPage', index);
  }
}

function prevPage() {
  if (props.pagNumber - 1 >= 1) {
    emit("loadPage", props.pagNumber - 1);
  }
}

function nextPage() {
  if (props.pagNumber + 1 <= numPages) {
    let endPage = pagesShow.value[pagesShow.value.length - 1]
    if (endPage <= numPages) {
      pagesShow.value = pagesShow.value.map(v => v+5)
      emit('loadPage', pagesShow.value[0]);
    }
  }
}

function goToFirstPage() {
  pagesShow.value = numPages>5?[1, 2, 3, 4, 5]:numPages
  pagesEnd.value = [numPages]
  pagesMiddle.value = []

  // go to last page
  emit('loadPage', pagesShow.value[0]);
}

function goToLastPage() {
  let tempArray = []
  pagesEnd.value = [ numPages ]

  if(numPages > 1 && pagesEnd.value[0]>5) {
    for (var i = pagesEnd.value[pagesEnd.value.length - 1]; i > (pagesEnd.value[0] - 5); i--) {
      tempArray.push(i)
    }
  } else if(pagesEnd.value[0]<5) {
      tempArray = [numPages]
  }
  
  pagesEnd.value = tempArray.reverse()
  pagesShow.value = numPages>5?[1, 2, 3, 4, 5]:numPages

  // go to last page
  if(numPages > 1) {
    emit('loadPage', pagesEnd.value[pagesEnd.value.length - 1]);
  } 
}

function middleLoadPage(index) {
  // one before
  pagesShow.value = [1]

  // one last
  pagesEnd.value = [numPages]

  // 3 middle, one before one after
  pagesMiddle.value = [(index-1), index, (index+1)]
  emit('loadPage', index);
}
</script>

<template>
  <div class="custom-table-pagination-wrapper" v-if="numPages > 1">
    <div class="custom-table-paginator-content">
      <button
        class="btn-prev btn-paginator"
        :class="[pagesShow[0] === 1 ? 'disabled' : '']"
        v-if="numPages > 1"
        @click="prevPage"
      >
        <font-awesome-icon icon="fa-solid fa-chevron-left" />
      </button>
      <div
        class="paginator-number btn-paginator"
        v-for="index in pagesShow"
        :key="index"
        :class="index == props.pagNumber ? 'selected' : ''"
        @click="goToPage(index)"
      >
        {{ index }}
      </div>
      <div v-if="pagesShow[4] < numPages || pagesEnd.length==5"
        class="btn-paginator btn-punctilio">
        ...
      </div>
      <div v-if="pagesShow[4] < numPages || pagesEnd.length==5"
        class="paginator-number btn-paginator"
        v-for="index in pagesEnd"
        :key="index"
        :class="index == props.pagNumber ? 'selected' : ''"
        @click="goToPage(index)"
      >
        {{ index }}
      </div>
      <div v-if="pagesMiddle.length > 0"
        class="btn-paginator btn-punctilio">
        ...
      </div>
      <div v-if="pagesMiddle.length > 0"
        class="paginator-number btn-paginator"
        v-for="index in pagesMiddle"
        :key="index"
        :class="index == props.pagNumber ? 'selected' : ''"
        @click="goToPage(index)"
      >
      {{ index }}
      </div>
      <div v-if="pagesMiddle.length > 0"
        class="btn-paginator btn-punctilio">
        ...
      </div>
      <button
        class="btn-next btn-paginator"
        :class="[(pagesShow[pagesShow.length -1] < numPages && pagesMiddle.length==0)  ? 'activeNext' : '']"
        v-if="numPages > 1"
        @click="nextPage"
      >
        <font-awesome-icon icon="fa-solid fa-chevron-right" />
      </button>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.custom-table-pagination-wrapper {
  position: -webkit-sticky;
  position: sticky;
  bottom: 0;
  z-index: 1;    
  height: 50px;
  display: flex;
  padding-top: 4px;
  align-items: baseline;
  justify-content: end;
  font-size: 13px;
  background-color: transparent;
  .custom-table-paginator-content {
    display: flex;
    color: #dcdcdc;
    background-color: white;
    border-radius: 6px;
    .paginator-number {
      font-weight: 600;
      &.selected {
        color: v-bind(userColor);
      }
    }
    .btn-paginator {
      background-color: white;
      color: #dcdcdc;
      border: 1px solid #dcdcdc;
      padding: 5px 10px;
      cursor: pointer;
      &:first-child {
        border-top-left-radius: 6px;
        border-bottom-left-radius: 6px;
        padding-left: 12px;
      }
      &:last-child {
        border-top-right-radius: 6px;
        border-bottom-right-radius: 6px;
        padding-right: 12px;
      }
      &.disabled {
        background-color: whitesmoke;
      }
      &.activeNext {
        background-color: v-bind(userColor);
        border: 1px solid v-bind(userColor);
        color: white;
      }
      &.btn-punctilio {
        cursor: no-drop;
      }
    }
  }
}
</style>
