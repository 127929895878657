import { defineStore, acceptHMRUpdate } from 'pinia'
import { url } from '@/conf/conf.js';

export const useUserPermission = defineStore({
    id: "userPermission",
    state: () => ({
        userPermissionData: [],
        organization: {},
        user: {},
        vehicle: {},
        workfile: {},
        yauty: {} 
    }),
    getters: {
        getUserPermission(state) {
            return state.userPermissionData
        },
        getOrganizationPermissions(state) {
            return state.organization
        },
        getPermissionsUser(state) {
            return state.user
        },
        getPermissionsVehicle(state) {
            return state.vehicle
        },
        getPermissionsWorkfile(state) {
            return state.workfile
        },
        getPermissionsYauty(state) {
            return state.yauty
        }                       
    },
    actions: {
        async fetchUserPermissions(params) {
            try {
                return await fetch(url, 
                    {
                        method: 'POST',
                        headers: {
                            'Accept': 'application/json',
                            'Content-Type': 'application/json'
                        },
                        body: JSON.stringify({ 
                            validation_key: params.k,
                            method: "getAuthenticatedUserPermissions"
                        })
                    })
                    .then((response) => response.json())
                    .then((data) => {
                        this.userPermissionData = data;
                        if(data.permissions) {
                            this.organization.manage                = data.permissions.find(element => element.permission_code == 'ORGANIZATION.MANAGE');
                            this.organization.userPermissions       = data.permissions.find(element => element.permission_code == 'USER_PERMISSIONS.MANAGE');
                            this.organization.userVehicles          = data.permissions.find(element => element.permission_code == 'USER_VEHICLES.MANAGE');
                            this.organization.userClients           = data.permissions.find(element => element.permission_code == 'USER_CLIENTS.MANAGE');
                            this.organization.userCreate            = data.permissions.find(element => element.permission_code == 'USER.CREATE');
                            
                            this.vehicle.create                     = data.permissions.find(element => element.permission_code == 'VEHICLE.CREATE');
                            this.vehicle.update                     = data.permissions.find(element => element.permission_code == 'VEHICLE.UPDATE');

                            this.workfile.validate                  = data.permissions.find(element => element.permission_code == 'WORKFILE.VALIDATE');
                            this.workfile.appointment_create        = data.permissions.find(element => element.permission_code == 'WORKFILE.APPOINTMENT_CREATE');

                            this.yauty.admin                        = data.permissions.find(element => element.permission_code == 'YAUTY.ADMIN');
                        }
                        return data
                    })
                    .catch((error) => {
                        console.error("Error:", error);
                    }
                );
            }
            catch (error) {
                console.log(error)
            }
        },
    }
})

if (import.meta.hot) {
    import.meta.hot.accept(acceptHMRUpdate(useUserPermission, import.meta.hot))
}