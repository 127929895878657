import { createApp } from "vue";
import { createPinia } from "pinia";
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
// import yautyTooltip from './directives/YautyTooltip.js';
import * as FontAwesomeYauty from './utils/FontAwesomeYauty.js';
import { installToaster } from 'maz-ui'
import { popoverr } from './utils/BootstrapDirectives.js';
import { Popover } from 'bootstrap'
import { i18n } from './utils/i18n.js';
import VueDatePicker from '@vuepic/vue-datepicker';
import App from "./App.vue";
import router from "./router";
import 'maz-ui/styles' 
import "bootstrap";
import './index.css';
import './styles/commons.scss';
import './styles/theme.css';
import './styles/colors.scss';
import './styles/variables.scss';
import '@vuepic/vue-datepicker/dist/main.css'

// DEFAULT OPTIONS
const toasterOptions = {
    position: 'bottom-right',
    timeout: 10_000,
    persistent: false,
  };
  
const app = createApp(App);
app.component('font-awesome-icon', FontAwesomeIcon);
app.component('VueDatePicker', VueDatePicker);
app.directive('popoverr', popoverr);
app.use(installToaster, toasterOptions);
// app.directive('vTooltip', yautyTooltip);
app.use(createPinia());
app.use(router);
app.use(i18n);
app.mount("#app"); 